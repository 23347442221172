<template>
  <div class="fixed top-16 left-[10%] z-[100]">
    <HistoryBack :disableAction="true" @backParent="goBack" />
  </div>
  <div class="profileContainer text-sm mt-8">
    <Onboarding @refresh="handleRefresh" />
    <div
      v-if="
        !showGeneralProfile &&
        !showMyPay &&
        !showMyFav &&
        !showMyClubs &&
        !showMyPrivacity
      "
      class=""
    >
      <div class="topProfile flex justify-center items-center flex-col">
        <ProfileImage />
        {{ email }}
        <ProfileName />
        <ProfileNickName />
        <ProfileGender />

        <div class="w-64">
          <SportSelector v-model="sport" />
        </div>
      </div>
      <div class="bodyProfile p-6 pt-12">
        <p v-if="mounted" class="mb-4">
          {{ $t("level") + " " + results.level }}
        </p>
        <ProgressBar
          v-if="mounted"
          :firstValue="MIN_LEVEL"
          :lastValue="MAX_LEVEL"
          :value="results.level"
        />
        <WinRate v-if="mounted" :results="results" />
        <div class="text-sm altBackground p-5 br16 mt-6">
          <div
            @click="showGeneralProfile = true"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="circleIcon" />
            </div>
            <div>{{ $t("my_matches") }}</div>
          </div>
          <div
            @click="showOnBoarding()"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="manIcon" />
            </div>
            <div>{{ $t("my_preferences") }}</div>
          </div>

          <div
            @click="showMyPay = true"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="myPayIcon" />
            </div>
            <div>{{ $t("my_payments") }}</div>
          </div>
          <div
            @click="showMyFav = true"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="myFavIcon" />
            </div>
            <div>{{ $t("my_favorites") }}</div>
          </div>
          <div
            @click="showMyClubs = true"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="myClubIcon" />
            </div>
            <div>{{ $t("my_linked_clubs") }}</div>
          </div>
          <div
            @click="showMyPrivacity = true"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="circleIcon" />
            </div>
            <div>{{ $t("my_privacy") }}</div>
          </div>
          <div
            @click="$router.push('/remove-account')"
            class="items-center cursor-pointer flex p-2 w-36 md:w-44 m-center"
          >
            <div class="mr-2">
              <SvgContainer name="circleIcon" />
            </div>
            <div>{{ $t("remove_account") }}</div>
          </div>
        </div>
      </div>
    </div>
    <MyMatches v-if="showGeneralProfile" />
    <MyPays v-if="showMyPay" />
    <MyLikes v-if="showMyFav" />
    <MyClubs v-if="showMyClubs" :customerId="customerId" />
    <MyPrivacy v-if="showMyPrivacity"
      @refresh="handleRefreshPrivacy"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ProfileImage from "@/components/myProfile/profileImage.vue";
import ProfileName from "@/components/myProfile/profileName.vue";
import ProfileNickName from "@/components/myProfile/profileNickName.vue";
import SportSelector from "@/components/form/sportSelector.vue";
import { Sport, MIN_LEVEL, MAX_LEVEL } from "@/enum/constants";
import ProgressBar from "@/components/form/progressBar.vue";
import WinRate from "@/components/myProfile/winRate.vue";
import CustomerHelper from "@/helpers/customerHelper";
import MyLikes from "@/components/myProfile/myLikes.vue";
import MyPays from "@/components/myProfile/myPays.vue";
import MyPrivacy from "@/components/myProfile/myPrivacy.vue";
import MyMatches from "@/components/myProfile/myMatches.vue";
import MyClubs from "@/components/myProfile/myClubs.vue";
import HistoryBack from "@/components/form/historyBack.vue";
import store from "@/store";
import SvgContainer from "@/views/SvgContainer.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import Onboarding from "./onboarding/onboarding.vue";
import ProfileGender from "@/components/myProfile/profileGender.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    ProfileImage,
    ProfileName,
    ProfileNickName,
    SportSelector,
    ProgressBar,
    WinRate,
    MyLikes,
    MyPays,
    MyPrivacy,
    MyMatches,
    MyClubs,
    SvgContainer,
    HistoryBack,
    Onboarding,
    ProfileGender,
  },
  beforeRouteLeave(to, from, next) {
    store.commit("closeOnboarding");
    next();
  },
  async mounted() {
    if (window.innerWidth > 768) {
      document.body.style.background =
        "url(/img/background-test.485cbfa3.png) top 100% center";
      document.body.style.backgroundRepeat = "no-repeat";
    }

    this.getResults();
    this.fixIphoneHeight();
    if (CustomerHelper.isUserLogged()) {
      this.customerId = await CustomerHelper.getCustomer().id;
      const user = await CustomerHelper.getUser();
      this.email = user.username;
    }
  },
  watch: {
    sport() {
      this.results = this.sportsArray.find(
        (sport) => sport.sport.code === this.sport
      );
      if (this.results.level < MIN_LEVEL) {
        this.results.level = MIN_LEVEL;
      }
    },
  },
  data() {
    return {
      showGeneralProfile: false,
      showMyPay: false,
      showMyFav: false,
      showMyClubs: false,
      showMyPrivacity: false,
      myPrivacityCheck: false,
      results: null,
      sport: Sport.PADEL,
      sportsArray: [],
      mounted: false,
      customerId: "",
      email: "",
      MIN_LEVEL:MIN_LEVEL,
      MAX_LEVEL:MAX_LEVEL

    };
  },
  methods: {
    async handleRefresh() {
      this.isLoading = true;
      const preferences = await CustomerHelper.getPreferences();
      CustomerHelper.updateStoragePreferences(preferences);
      this.isLoading = false;
    },
    async handleRefreshPrivacy(privacy) {
      CustomerHelper.updateStoragePrivacy(privacy);
    },
    showOnBoarding() {
      const jsonData = {
        myPreferences: "1",
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      store.commit("openOnboarding");
    },
    togglePrivacy() {
      this.myPrivacityCheck = !this.myPrivacityCheck;
    },
    async getResults() {
      this.sportsArray = await CustomerHelper.getCustomerResults();
      this.results = this.sportsArray.find(
        (sport) => sport.sport.code === this.sport
      );
      this.mounted = true;
    },
    goBack() {
      if (
        !this.showGeneralProfile &&
        !this.showMyPay &&
        !this.showMyFav &&
        !this.showMyClubs &&
        !this.showMyPrivacity
      ) {
        this.$router.push("/");
        return;
      }

      this.showGeneralProfile = false;
      this.showMyPay = false;
      this.showMyFav = false;
      this.showMyClubs = false;
      this.showMyPrivacity = false;
      this.myPrivacityCheck = false;
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".profileContainer ");
        if (resultsContainer) {
          resultsContainer.classList.add("pb-100");
        }
      }
    },
  },
});
</script>

<style scoped>
@media (min-width: 767px) {
  .profileContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
}
.profileContainer .m-center {
  margin: 0 auto;
}
.profileContainer .backButton {
  top: 10px;
  left: 20px;
}
.profileContainer .br100 {
  border-radius: 50%;
}
.profileContainer .topProfile {
  position: relative;
  top: 30px;
}
.profileContainer .gradiend {
  background: linear-gradient(
    103.18deg,
    var(--color1) 20.29%,
    var(--color2) 97.77%
  );
}
.MyClubs .ClubElements {
  border: 1px solid rgba(255, 255, 255, 0.089);
}

.MyClubs .opacityClubs {
  opacity: 0.7;
}
</style>