<template>
    <div class="WrapperPrivacy mx-auto max-w-2xl overflow-auto flex justify-center flex-col items-center sm:w-full sm:p-6">
      <p class="mt-6 text-center text-3xl">{{ $t("private_policy") }}</p>
      <p class="mt-6 text-justify tp-4" v-html="$t('privacy_policy_full_text')"></p>
    </div>
  </template>

<style scoped>
.WrapperPrivacy {
    max-width: 620px;
}
.opacity {
    opacity: 0.6;
}
</style>