import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b140669"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center h-full" }
const _hoisted_2 = { class: "flex overflow-hidden min-w-120 md:min-w-180" }
const _hoisted_3 = { class: "w-full flex justify-center mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "pb-2 relative" }
const _hoisted_6 = { class: "containerImageMatch br100" }
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = {
  key: 0,
  class: "absolute scoreUsers"
}
const _hoisted_9 = { class: "numberScore absolute" }
const _hoisted_10 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.players && _ctx.players.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPlayers, (player) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: player.playerId,
                  class: "p-2 min-h-80 flex flex-col items-center"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("img", {
                        alt: player.name,
                        src: player.image ? player.image : _ctx.DEFAULT_IMAGE
                      }, null, 8, _hoisted_7)
                    ]),
                    (_ctx.isMatch && _ctx.hasLevel(player))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_SvgContainer, { name: "playerLevelStar" }),
                          _createElementVNode("div", _hoisted_9, _toDisplayString(player.level?.toFixed(2)), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getPlayerName(player)), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}