import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33d17182"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = { class: "flex items-center mt-3" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "flex mt-8 justify-center items-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "input-component relative mt-1 flex justify-center" }
const _hoisted_7 = ["step", "min", "max"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "flex mt-8 justify-center" }
const _hoisted_10 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("select_your_level_for") + " " + _ctx.$t(_ctx.getSportLabel(_ctx.sport))), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: _normalizeClass(["selected-option mr-2", { 'disabled': _ctx.disableButton === 'increment' }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeValue(_ctx.LEVEL_STEP))),
        disabled: _ctx.disableButton === 'increment'
      }, "+", 10, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          class: "w-40 !pl-0 p-2 text-center",
          type: "number",
          readonly: "",
          step: _ctx.LEVEL_STEP,
          min: _ctx.min,
          max: _ctx.max,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.levelValue) = $event))
        }, null, 8, _hoisted_7), [
          [_vModelText, _ctx.levelValue]
        ])
      ]),
      _createElementVNode("button", {
        class: _normalizeClass(["selected-option ml-2", { 'disabled': _ctx.disableButton === 'decrement' }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeValue(-_ctx.LEVEL_STEP))),
        disabled: _ctx.disableButton === 'decrement'
      }, "-", 10, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("input", {
        class: "search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12 max-w-xs",
        type: "button",
        value: _ctx.$t('save'),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sendForm && _ctx.sendForm(...args)))
      }, null, 8, _hoisted_10)
    ])
  ]))
}