import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50f8b630"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "z-10 absolute"
}
const _hoisted_2 = { class: "cookie-notice" }
const _hoisted_3 = { class: "cookie-notice__text" }
const _hoisted_4 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isWebView)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade"
      }, {
        default: _withCtx(() => [
          (_ctx.showCookie)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("cookie_notice")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("cookie_message")), 1),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeCookieNotice && _ctx.closeCookieNotice(...args)))
                      }, _toDisplayString(_ctx.$t("i_agree")), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}