<template>
  <div class="mt-8">
    <div class="flex items-center justify-center"><p>{{ $t("my_privacy") }}</p></div>
    <div class="mt-20 br16 altBackground p-4 overflow-y-auto">
      <label class="ml-12" v-if="onlyLikesCanFindMeLabel">
        <input
          type="checkbox"
          v-model="bodyPrivacy.onlyLikesCanFindMe"
          @change="togglePrivacy"
        />
        {{ $t(onlyLikesCanFindMeLabel) }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CustomerHelper from "@/helpers/customerHelper";
import { Privacy } from "models";

export default defineComponent({
  name: "MyPrivacy",
  emits: ["refresh"],
  data() {
    const bodyPrivacy: Privacy = {
      onlyLikesCanFindMe: false,
    };
    return {
      result: bodyPrivacy,
      onlyLikesCanFindMeLabel: "",
      bodyPrivacy,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.result = await CustomerHelper.getPrivacy();
      const keys = Object.keys(this.result);
      this.onlyLikesCanFindMeLabel = CustomerHelper.getPrivacyLabel(keys[0]);
      this.bodyPrivacy = this.result;
    },
    async togglePrivacy() {
      await CustomerHelper.updatePrivacy(this.bodyPrivacy);
      this.$emit("refresh", this.result);
    },
  },
});
</script>

<style scoped>
</style>