<template>
  <div>
    <div class="flex button items-center cursor-pointer" v-on:click="openModal">
      <div class="flex pl-2">
        <SvgContainer :name="getSportLabels()" />
      </div>
      <div class="flex p-2">{{ $t(sportLabel) }}</div>
    </div>
    <Transition>
      <PopUpLight v-if="isModalOpen" @closed="this.closeModal()">
        <SportSelector
          v-model="selectedSport"
          @sportSelected="selectSport"
        ></SportSelector>
      </PopUpLight>
    </Transition>
  </div>
</template>

<script>
import SvgContainer from "@/views/SvgContainer.vue";
import { useStore } from "vuex";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Sport, SportIconNames } from "@/enum/constants";
import SportSelector from "@/components/form/sportSelector.vue";
import { getSportLabel } from "@/helpers/dataHelper";

export default {
  name: "radioButtonSportPopup",
  emits: ["sportSelected"],

  components: {
    SvgContainer,
    PopUpLight,
    SportSelector,
  },
  data() {
    return {
      isModalOpen: false,
      selectedSport: Sport.PADEL,
      store: null,
      sportLabel:""
    };
  },
  mounted() {
    this.store = useStore();

    let sport = this.$route.query.sport;
    if (sport) {
      this.selectSport(parseInt(sport));
      return;
    }

    sport = this.store.state.preferences.sport
      ? this.store.state.preferences.sport
      : Sport.PADEL;
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    selectSport(selected) {
      this.selectedSport = selected;
      this.error = "";
      this.$emit("sportSelected", selected);
      this.closeModal();
    },
    getSportLabels() {
      this.sportLabel = getSportLabel(this.selectedSport)
      switch (this.selectedSport) {
        case Sport.PADEL:
          return SportIconNames.PADEL;
        case Sport.TENIS:
          return SportIconNames.TENIS;
        case Sport.PICKLEBALL:
          return SportIconNames.PICKLEBALL;
        case Sport.SOCCER:
          return SportIconNames.SOCCER;
      }
    },
  },
  computed: {
    isValid() {
      return this.selectedSport !== "";
    },
  },
};
</script>
<style scoped>
.button :hover {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.button{
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  padding: 8px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264B67;
  border-left-color: #264B67;
  border-right-color: #076AE3;
  border-top-color: #264B67;
}
</style>
