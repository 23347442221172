<template>
  <div>
    <span v-if="!onlyOneSport()" class="flex justify-center items-center m-5"
      >
      <div>{{ $t("sport") }}</div>
      <br />
      <div class="flex">
        <span
          v-if="hasPadel()"
          class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
          @click="setActiveSport(sport.PADEL)"
          :class="{ active: activeSport == sport.PADEL }"
        >
          <SvgContainer name="homeModalPadelIcon" />
        </span>
        <span
          v-if="hasTenis()"
          class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
          @click="setActiveSport(sport.TENIS)"
          :class="{ active: activeSport == sport.TENIS }"
        >
          <SvgContainer name="homeModalTenisIcon" />
        </span>
        <span
          v-if="hasPickle()"
          class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
          @click="setActiveSport(sport.PICKLEBALL)"
          :class="{ active: activeSport == sport.PICKLEBALL }"
        >
          <SvgContainer name="homeModalPickleIcon" />
        </span>
        <span
          v-if="hasSoccer()"
          class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
          @click="setActiveSport(sport.SOCCER)"
          :class="{ active: activeSport == sport.SOCCER }"
        >
          <SvgContainer name="homeModalSoccerIcon" />
        </span>
      </div>
    </span>
    <div class="flex flex-wrap items-center justify-center m-auto overflow-y-auto mt-4 max-h-[560px]">
      <ActivityAvailability v-if="!errorMsg"
        :activities="activitiesDataSource"
      />
      <p v-else>{{ errorMsg }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SvgContainer from "@/views/SvgContainer.vue";
import ActivityAvailability from "@/components/search/activityAvailability.vue";
import {Category, SearchType, Sport} from "@/enum/constants";
import { formatDate, setTimeToDate } from "@/helpers/dateHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import { Format } from "@/helpers/formatHelper";

export default defineComponent({
  name: "bookActivities",
  props: ["tenantInfo"],
  components: {
    ActivityAvailability,
    SvgContainer
  },
  data() {
    return {
      activitiesDataSource:[],
      errorMsg: '' as string | null,
      activeDate: new Date() as Date,
      activeHour: '' as string,
      sport: Sport, // to use in html
      activeSport: Sport.PADEL,
    };
  },
  async created(){
    this.activeHour = this.$route.query.hour as string;
    this.activeSport =  Number(this.$route.query.sport);
    if (this.activeHour === undefined) {
      this.activeHour = "0";
    }
    this.activeDate = setTimeToDate(new Date(this.$route.query.date as string),this.activeHour);
    this.loadActivities();
  },
  methods: {
    async setActiveSport(sport: number) {
      this.activeSport = +sport;
      await this.loadActivities();
    },
    hasPadel() {
      return (this.tenantInfo.tenant.sports.find((y:number) => y === Sport.PADEL) !== undefined);
    },
    hasTenis() {
      return (this.tenantInfo.tenant.sports.find((y:number) => y === Sport.TENIS) !== undefined);
    },
    hasPickle() {
      return (this.tenantInfo.tenant.sports.find((y:number) => y === Sport.PICKLEBALL) !== undefined);
    },
    hasSoccer() {
      return (this.tenantInfo.tenant.sports.find((y:number) => y === Sport.SOCCER) !== undefined);
    },
    onlyOneSport() {
      return this.tenantInfo.tenant.sports.length === 1;
    },
    async loadActivities() {
      this.errorMsg = null;
      const request = {
        tenantIds: [this.$route.query.id],
        date: formatDate(this.activeDate),
        sport: this.activeSport,
        category: Category.ACTIVITY,
        searchType: SearchType.FULL,
      };
      const message = await BookingHelper.messageAvailability(request);
      if (Format.IsNull(message.activities) || message.activities.length === 0) {
        this.errorMsg = this.$t("there_are_no_activities_for_the_selected_date_try_another");
        return;
      }
      this.activitiesDataSource = message.activities;
      this.fixIphoneHeight();
    },
    async parentCallGetAvailability(date: any){
      const currentQuery = { ...this.$route.query };
      currentQuery.date = formatDate(date);
      this.$router.replace({ query: currentQuery }); //replace query date
      this.activeDate = date;
      await this.loadActivities();
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector("#ResultsContainer");
        if (resultsContainer) {
          resultsContainer.classList.add('pb-150');
          const newMatchBtn = document.querySelector(".createMatch");
          newMatchBtn!.classList.add('iOS-bottom-btn');
        }
      }
    }
  },
});
</script>
<style scoped>
</style>