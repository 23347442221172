<template>
    <div v-if="!AppsHelper.isAndroidWebView()"
        @click="goBack"
        class="walletButton back flex items-center justify-center"
    >
    <SvgContainer name="profileBackArrow" />
    </div>
</template>
<script lang="ts">
import SvgContainer from "@/views/SvgContainer.vue";
import { defineComponent } from 'vue';
import router from "@/router";
import { AppsHelper } from "@/helpers/appsHelper";

export default defineComponent({
    name: "HistoryBack",
    emits: ["backParent"],
    props: {
        disableAction: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
      SvgContainer,
    },
    data() {
        return {
        AppsHelper: AppsHelper,
        };
    },
    methods: {
        goBack() {
            if(this.disableAction){
                this.$emit("backParent");
                return;
            }
            if (window.history.length > 1) {
                this.sendBackToIOS();
                router.go(-1);
                return;
            }
            if(AppsHelper.isIOSWebView()){
                this.sendBackToIOS();
                return;
            }
            router.push('/');
        },
        sendBackToIOS(){
            const jsonData = {
                goBack: "true",
            };
            if (AppsHelper.sendToIOS(window, jsonData)) {
                return;
            }
        }
    },
});
</script>
<style scoped>
.back{
    cursor: pointer;
    width: 30px;
    height: 30px;
}
</style> 