<template>
  <div class="mt-8">
    <div class="flex items-center justify-center"><p>{{ $t("my_favorites") }}</p></div>
    <div v-if="result && result.length > 0" class="mt-12 br16 altBackground p-4 overflow-y-auto">
      <ul>
        <li v-for="like in result" :key="like.customerId">
            <div class="like justify-between">
              <div class="like-info flex justify-center items-center">
                  <img :src="like.image" :alt="like.fullName" />
              </div>
              <div class="like-info flex justify-center items-center">
                  <h3>{{ like.fullName }}</h3>
              </div>
              <div @click="iLike(like, $event)" class="like-info flex justify-center items-center cursor-pointer">
                  <SvgContainer name="blueHeartFilled" v-if="like.ilike === true"/>
                  <SvgContainer name="blueHeart" v-else/>
              </div>
            </div>
            <div class="linearSpace"></div>
        </li>
      </ul><!--
      <ListPagination
        :start = "start"
        :size = "size"
        @getData = "handleGetData"
      />-->
    </div>
    <div v-else class="flex items-center justify-center mt-12 br16 altBackground p-4"><p>{{ $t("dont_have_any_favorite_player") }}</p></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CustomerHelper from "@/helpers/customerHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import { Format } from "@/helpers/formatHelper";
import { CustomerLike } from "@/custom-models/customerLike";
import ListPagination from "@/components/form/listPagination.vue";
import store from '@/store';

export default defineComponent({
    name: "MyLikes",
    data() {
      return {
        start: 0,
        size: 50,
        result: [] as CustomerLike[],
      };
    },
    components: {
      SvgContainer,
      //ListPagination
    },
    async mounted() {
      //this.createLikesTEST()//Only to create friends to test component
      this.loadData(this.start, this.size);
    }, 
    methods: {
      async handleGetData(start:number, size:number){
        this.loadData(start, size);
      },
      async loadData(start:number, size:number){
          store.commit("setLoading", true);
          this.result = await CustomerHelper.findCustomerLike(start, size);
          store.commit("setLoading", false);
          if(Format.IsNull(this.result)){
            return;
          }
          this.result = this.result.map(item => {
            return {
              ...item, // Copy all existing properties from the original object
              ilike: true, // "ilike" to true all are liked at start
            };
          });
      },
      async iLike(like:CustomerLike, e: MouseEvent) {
          e.preventDefault();
          e.stopPropagation();
          if (Format.IsNull(like.customerId)) {
              return;
          }
          if(like.ilike){
            await CustomerHelper.deleteCustomerLike(like.customerId);
            like.ilike = false;
            return;
          }
          await CustomerHelper.createCustomerLike(like.customerId);
          like.ilike = true;
      },
      async createLikesTEST(){
        let customerId = "hash-2021";

        for (let i = 0; i < 18; i++) {
          const number = parseInt(customerId.split('-')[1]) + 1;
          customerId = `hash-${number}`;
          await CustomerHelper.createCustomerLike(customerId);
        }
      }
    }
});
</script>

<style scoped>
.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}
.like {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
.like img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.like-info{
  width: 33%;
  min-height: 80px;
}
</style>

