import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BookingApiAxiosParamCreator } from '../../apis/booking-api';
import { AvailabilityRequest, BookingRequest, BookingResult, Player, PricesRequest, TenantAvailabilityRequest } from 'models';
import { BaseApi } from './BaseApi';

export class BookingApi extends BaseApi {

  /**
  * 
  * @summary Get bookings availability
  * @param {AvailabilityRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async availability(body: AvailabilityRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().availability(body, options);

    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get bookings availability
  * @param {TenantAvailabilityRequest} 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async tenantAvailability(body: TenantAvailabilityRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().tenantAvailability(body, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get prices about the participants of a future booking
  * @param {PricesRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async getPrices(body: PricesRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().getPrices(body, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Create a booking
  * @param {BookingRequest} body 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async prebooking(body: BookingRequest, xTenant?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().prebooking(body, xTenant, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get a booking by id
  * @param {string} id The booking id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async findByHash1(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().findBookingById(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Add a player
  * @param {Player} body 
  * @param {string} id The booking id
  * @param {number} playerId The player id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async addPlayer(body: Player, id: string, playerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().addPlayer(body, id, playerId, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Delete a player
  * @param {string} id The booking id
  * @param {number} playerId The player id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async deletePlayer(id: string, playerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().deletePlayer(id, playerId, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Move a player
  * @param {string} id The booking id
  * @param {number} playerId The player id
  * @param {number} targetPlayerId The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async movePlayer(id: string, playerId: number, targetPlayerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().movePlayer(id, playerId, targetPlayerId, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Create a sale to pay players
  * @param {string} id The booking id
  * @param {Array<number>} playerIds The player id list
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async createSale(id: string, playerIds: Array<number>, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().createSale(id, playerIds, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Add a result to the booking
  * @param {BookingResult} body 
  * @param {string} id The booking id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async addResult(body: BookingResult, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const axiosArgs = await BookingApiAxiosParamCreator().addBookingResult(body, id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Get all customer's bookings
  * @param {number} start Zero-based page index
  * @param {number} size The page size
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async getCustomersBooking(start: number, size: number, owner?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await BookingApiAxiosParamCreator().findBookingByCustomer(start, size, owner, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }

  /**
  * 
  * @summary Cancel Booking
  * @param {string} id booking id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
  public static async cancelBooking(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<any>>{
    const axiosArgs = await BookingApiAxiosParamCreator().cancelBooking(id, options);
    return this.getAuthorizedAxios().request({
      url: axiosArgs.url,
      method: axiosArgs.options.method,
      headers: axiosArgs.options.headers,
      params: axiosArgs.options.params,
      data: axiosArgs.options.data,
    });
  }


}
