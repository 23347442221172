<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center mt-3">
      <div>
        <span class="mr-2">{{ $t("select_your_level_for") + " " + $t(getSportLabel(sport)) }} </span>
      </div>
    </div>
    <div class="flex mt-8 justify-center items-center">
        <button class="selected-option mr-2" 
          @click="changeValue(LEVEL_STEP)" 
          :class="{ 'disabled': disableButton === 'increment' }"
          :disabled="disableButton === 'increment'">+</button>
        <div class="input-component relative mt-1 flex justify-center">
            <input class="w-40 !pl-0 p-2 text-center"
                  type="number"
                  readonly
                  :step="LEVEL_STEP"
                  :min="min"
                  :max="max"
                  v-model="levelValue"
            />
        </div>
        <button class="selected-option ml-2" 
          @click="changeValue(-LEVEL_STEP)"
          :class="{ 'disabled': disableButton === 'decrement' }" 
          :disabled="disableButton === 'decrement'">-</button>
    </div>
    <div class="flex mt-8 justify-center">
      <input
        class="search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12 max-w-xs"
        type="button"
        :value="$t('save')"
        @click="sendForm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MOD_LEVEL, LEVEL_STEP } from "@/enum/constants";
import { getSportLabel } from "@/helpers/dataHelper";

export default defineComponent({
  name: "InputLevel",
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    sport:{
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      levelValue: this.modelValue as number | null,
      MOD_LEVEL: MOD_LEVEL,
      LEVEL_STEP: LEVEL_STEP,
      disableButton: ""
    };
  },
  mounted() {
    if (!isNaN(this.modelValue as number)) {
      this.levelValue = parseFloat((this.modelValue as number).toFixed(2));
      this.levelValue <= 0 ? this.disableButton = 'decrement' : this.disableButton = '';
    }
  },
  methods: {
    getSportLabel,
    changeValue(change: number) {
      this.levelValue = parseFloat((this.levelValue! + change).toFixed(2));
      if(this.levelValue == parseFloat((this.modelValue + MOD_LEVEL).toFixed(2))){
        this.disableButton = 'increment';
      }
      if(this.levelValue == parseFloat((this.modelValue - MOD_LEVEL).toFixed(2)) || this.levelValue <= 0){
        this.disableButton = 'decrement';
      }
    },
    sendForm(){
      this.$emit("level", this.levelValue);
    }
  },
});
</script>
<style scoped>
.selected-option {
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  width: 44px;
  padding: 10px;
  font-size: 39px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
  justify-content: space-evenly;
}
.disabled {
  background: transparent !important;
  opacity: 0.6;
  cursor: default;
}
</style>