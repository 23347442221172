<template>
  <Transition>
    <PopUpLight
    v-if="showGenderLevel"
      @closed="closeGenderLevel()"
      :showClose="false"
    >
      <UpdateUser
        :customSport="sport"
        @closeGenderLevel="closeGenderLevel()"
      />
    </PopUpLight>
  </Transition>  
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UpdateUser from "@/components/match/updateUser.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import {customGlobalError} from "@/helpers/errorHelper";

export default defineComponent({
  name: "LevelingGender",
  components: {
    PopUpLight,
    UpdateUser
  },
  data() {
    return {
      user: null,
      errorMsg: null as string | null,
      showGenderLevel: false,
      sport: 0
    };
  },
  async mounted() {

    if (!Format.IsNull(this.$route.params.userToken) &&
        !Format.IsNull(this.$route.params.sportId)
        ) {
          this.sport= Number(this.$route.params.sportId);
          localStorage.setItem("t", JSON.stringify(atob(this.$route.params.userToken as string)));
          this.showGenderLevel = true;
    } else {
      customGlobalError(this.$t("wrong_url"))
      return;
    }
  },
  methods: {
    closeGenderLevel() {
      this.showGenderLevel = false;
    },
  },
});
</script>
<style scoped>
</style>