<template>
  <div :class="cssClass">
    <div>{{ $t("sport") }}</div>
    <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
      <div
        class="flex flex-col items-center"
        @click="click($event, sport.TENIS)"
      >
        <div
          class="mb-2 p-3 br100"
          :class="{ selected: modelValue === sport.TENIS }"
        >
          <img src="../../assets/images/tenis.svg" alt="" />
        </div>
        <div>{{ $t("tennis") }}</div>
      </div>
      <div
        class="flex flex-col items-center"
        @click="click($event, sport.PADEL)"
      >
        <div
          class="mb-2 p-3 br100"
          :class="{ selected: modelValue === sport.PADEL }"
        >
          <img src="../../assets/images/iconPadel.svg" alt="" />
        </div>
        <div>{{ $t("padel") }}</div>
      </div>
      <div
        class="flex flex-col items-center"
        @click="click($event, sport.PICKLEBALL)"
      >
        <div
          class="mb-2 p-3 br100"
          :class="{ selected: modelValue === sport.PICKLEBALL }"
        >
          <img src="../../assets/images/Pickleball.svg" alt="" />
        </div>
        <div>{{ $t("pickleball") }}</div>
      </div>
      <div
        class="flex flex-col items-center"
        @click="click($event, sport.SOCCER)"
      >
        <div
          class="mb-2 p-3 br100"
          :class="{ selected: modelValue === sport.SOCCER }"
        >
          <img src="../../assets/images/soccer-ball.svg" alt="" />
        </div>
        <div>{{ $t("soccer") }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Sport } from "@/enum/constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SportSelector",
  components: {},
  props: {
    modelValue: Number,
    cssClass: String,
  },

  data() {
    return {
      sport: Sport,
    };
  },
  methods: {
    click(e: any, sport: any) {
      this.$emit("update:modelValue", sport);
      this.$emit("sportSelected", sport);
    },
  },
});
</script>

<style scoped>
.selected {
  background-color: var(--color2);
}

img {
  height: 20px;
  width: 20px;
}
</style>
