import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-8" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "mt-20 br16 altBackground p-4 overflow-y-auto" }
const _hoisted_4 = {
  key: 0,
  class: "ml-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("my_privacy")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.onlyLikesCanFindMeLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bodyPrivacy.onlyLikesCanFindMe) = $event)),
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePrivacy && _ctx.togglePrivacy(...args)))
            }, null, 544), [
              [_vModelCheckbox, _ctx.bodyPrivacy.onlyLikesCanFindMe]
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.onlyLikesCanFindMeLabel)), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}