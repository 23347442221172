<template>
  <div
    class="md:pb-8 h-1/2"
    v-if="tenantInfo !== null && tenantInfo.tenant !== undefined"
  >
    <div>
      <span
        class="flex justify-center items-center m-5"
        v-if="!onlyOneSport()"
      >
        <div>{{ $t("sport") }}</div>
        <br />
        <div class="flex">
          <span
            v-if="hasPadel()"
            class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
            @click="setActiveSport(sport.PADEL)"
            :class="{ active: activeSport == sport.PADEL }"
          >
            <SvgContainer name="homeModalPadelIcon" />
          </span>
          <span
            v-if="hasTenis()"
            class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
            @click="setActiveSport(sport.TENIS)"
            :class="{ active: activeSport == sport.TENIS }"
          >
            <SvgContainer name="homeModalTenisIcon" />
          </span>
          <span
            v-if="hasPickle()"
            class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
            @click="setActiveSport(sport.PICKLEBALL)"
            :class="{ active: activeSport == sport.PICKLEBALL }"
          >
            <SvgContainer name="homeModalPickleIcon" />
          </span>
          <span
            v-if="hasSoccer()"
            class="cursor-pointer iconBookResults h-10 w-10 ml-2 mr-2 flex justify-center items-center"
            @click="setActiveSport(sport.SOCCER)"
            :class="{ active: activeSport == sport.SOCCER }"
          >
            <SvgContainer name="homeModalSoccerIcon" />
          </span>
        </div>
      </span>
      <div class="flex flex-wrap items-center justify-center m-auto overflow-y-auto mt-4 max-h-[560px]">
        <MatchAvailability
          :matches="matches || []"
          fromRouteName="booking"
          :activeSport="activeSport"
          @matchSelected="matchSelected"
          @createMatch="createMatch"
          ref="matchAvailability" 
        />
      </div>
    </div>
    <Transition>
      <PopUpLight
        v-if="showGenderLevel"
        @closed="closeGenderLevel()"
        :showClose="false"
      >
      <UpdateUser
        :customSport="activeSport"
        @closeGenderLevel="closeGenderLevel()"
      />
    </PopUpLight>
  </Transition>
  <Transition>
      <PopUpLight v-if="errorMsg" @closed="closeError()">
        <div class="mt-8">
          {{ errorMsg }} 
        </div>
        <div @click="createMatchClicked($event)" class="createMatch p-4 br60 cursor-pointer mt-12">
          {{ $t("create_match") }}
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
  
<script type="ts">
import SvgContainer from "@/views/SvgContainer.vue";
import { Category, Sport, GlobalErrors } from "@/enum/constants";
import { setTimeToDate, formatDate, beautifyDate, beautifyDateWithOutTime, } from "@/helpers/dateHelper";
import { checkURLBookingFormat } from "@/helpers/dataHelper";
import { SearchType } from "@/enum/constants";
import MatchAvailability from "@/components/search/matchAvailability.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import { Format } from "@/helpers/formatHelper";
import store from '@/store';
import { BookingHelper } from "@/helpers/bookingHelper";
import CustomerHelper from "@/helpers/customerHelper";
import UpdateUser from "@/components/match/updateUser.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { NO_LEVEL } from "@/enum/constants";

export default {
  name: "bookMatches",
  props: ["tenantInfo"],
  emits: ["privateClub"],
  components: {
    SvgContainer,
    MatchAvailability,
    UpdateUser,
    PopUpLight
  },
  data() {
    return {
      hours: [],
      dateStr: "",
      showCalendar: false,
      sport: Sport, // to use in html
      activeHour: "",
      activeSport: Sport.PADEL,
      tenantId: null,
      activeDate: new Date(),
      matches: [],
      category: Category,
      showGenderLevel: false,
      isUserLogged: false,
      level: null,
      errorMsg: null
    };
  },
  async created() {
    if (!checkURLBookingFormat) {
      return;
    }
    this.activeSport = +this.$route.query.sport;
    this.isUserLogged = CustomerHelper.isUserLogged();
    if(this.isUserLogged){
      await this.initGenderLevel();
    }

    this.tenantId = this.$route.query.id;
    this.activeHour = this.$route.query.hour;
    if (this.activeHour === undefined) {
      this.activeHour = "0";
    }

    this.activeDate = setTimeToDate(new Date(this.$route.query.date), this.activeHour);
    this.dateStr = beautifyDate(this.activeDate, true, false);

    store.commit("setLoading", true);
    await this.loadMatches();
    store.commit("setLoading", false);
  },
  methods: {
    createMatchClicked(event) {
      // Call the createMatchClicked method of MatchAvailability child component
      this.$refs.matchAvailability.createMatchClicked(event);
    },
    async initGenderLevel() {
      const hasGender = await CustomerHelper.hasGender();
      const level = await CustomerHelper.getSportLevel(this.activeSport);
      let hasLevel = false;
      if (!Format.IsNull(level)) {
        this.level = level;
        if (level != NO_LEVEL) {
          hasLevel = true;
        }
      }
      if (hasGender && hasLevel) {
        this.showGenderLevel = false;
        return;
      }
      this.showGenderLevel = true;
    },
    matchSelected(match) {
      let url = "/booking-join/" + match.id;
      if (match.side !== undefined) {
        url += "?s=" + match.side;
      }
      if (match.team !== undefined) {
        url += "&t=" + match.team;
      }
      this.$router.push(url);
    },
    closeError() {
      this.errorMsg = null;
    },
    createMatch() {
      var request = {
        name: "search-results",
        query: {
          tenantIds: this.tenantId,
          date: this.$route.query.date,
          sport: this.activeSport,
          category: this.category.BOOKING,
          hour: this.activeHour,
          radius: 3,
          searchType: SearchType.FULL,
          createMatchMode: true,
        },
      };
      return this.$router.push(request);
    },
    async loadMatches() {
      this.matches = null;
      if(this.showGenderLevel){
        return;
      }
      const request = {
        tenantIds: [this.$route.query.id],
        date: this.getDateSystemFormat(),
        sport: this.activeSport,
        category: Category.MATCH,
        searchType: SearchType.FULL,
      };
      try {
        const message = await BookingHelper.messageAvailability(request);
        if (Format.IsNull(message.matches) || message.matches.length === 0) {
          this.errorMsg = this.$t("there_are_no_matches_for_the_selected_date_go_ahead_and_create_one");
          return;
        }
        this.matches = message.matches;
      } catch (error) {
        if (error.response.status === GlobalErrors.ERROR_PRIVATE_CLUB) {
          this.$emit("privateClub");
        }
        return;
      }

      this.fixIphoneHeight();
    },
    async parentCallGetAvailability(date){
      const currentQuery = { ...this.$route.query };
      currentQuery.date = formatDate(date);
      this.$router.replace({ query: currentQuery });
      this.activeDate = date;
      this.dateStr = beautifyDateWithOutTime(this.activeDate, true, false);
      await this.loadMatches();
    },
    async setActiveSport(sport) {
      this.activeSport = +sport;
      await this.initGenderLevel();
      this.activeHour = "";
      await this.loadMatches();
    },
    hasPadel() {
      return (this.tenantInfo.tenant.sports.find((y) => y === Sport.PADEL) !== undefined);
    },
    hasTenis() {
      return (this.tenantInfo.tenant.sports.find((y) => y === Sport.TENIS) !== undefined);
    },
    hasPickle() {
      return (this.tenantInfo.tenant.sports.find((y) => y === Sport.PICKLEBALL) !== undefined);
    },
    hasSoccer() {
      return (this.tenantInfo.tenant.sports.find((y) => y === Sport.SOCCER) !== undefined);
    },
    onlyOneSport() {
      return this.tenantInfo.tenant.sports.length === 1;
    },
    getDateSystemFormat() {
      return formatDate(this.activeDate);
    },
    async closeGenderLevel() {
      this.showGenderLevel = false;
      store.commit("setLoading", true);
      await this.loadMatches();
      store.commit("setLoading", false);
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector("#ResultsContainer");
        if (resultsContainer) {
          resultsContainer.classList.add('pb-150');
          const newMatchBtn = document.querySelector(".createMatch");
          newMatchBtn.classList.add('iOS-bottom-btn');
        }
      }
    }
  },
};
</script>
<style scoped>
.createMatch {
  background: linear-gradient(90deg, var(--color1) 35%, var(--color2) 100%);
  text-align: center;
  min-width: 200px;
}
</style>