<template>
  <span>
    <HeaderView v-if="!isWebView"></HeaderView>
    <div v-if="!iosWebview" class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
    <div
      :class="{ 'mt-8': isWebView }"
      class="flex justify-center"
      v-if="paymentData.paymentProviderData !== undefined"
    >
      <div class="modalPayment pl-2 pr-2 mt-2">
        <div class="relative h-full">
          <div>
            <div class="mb-4 sm:text-sm" v-if="cards.length > 0">
              {{ $t("credit_cards").toUpperCase() }}
            </div>
            <div
              class="flex justify-between w-full bg-zinc-700 cardOption p-4 items-center mb-6"
              :class="{ walletButton: cardId === card.token }"
              v-for="card in cards"
              :key="card.token"
            >
              <div class="flex items-center">
                <div
                  class="w-16 h-10 bg-white flex justify-center items-center mr-4 p-3 br8"
                >
                  <img :alt="card.brand" :src="card.logoUrl" />
                </div>
                <div>
                  <p>{{ card.brand }}</p>
                  <p class="playerSubtitle">
                    **** **** **** {{ getLast4Digits(card.number || "") }}
                  </p>
                </div>
              </div>
              <div
                class="cursor-pointer deleteCard p-4 br100"
                @click="deleteCard(card.token || '', $event)"
              >
                <SvgContainer name="deleteCard" />
              </div>
            </div>
          </div>
          <div class="mt-8 w-full wrapperBottomWallet">
            <div
              v-if="showPolicy"
              class="checkoutPolicy cursor-pointer mt-5 pb-4"
              :class="{ openPolicity: openLegal }"
              v-on:click="openLegal = !openLegal"
            >
              <div class="flex justify-between mb-4">
                <div class="flex justify-center items-center sm:text-sm">
                  {{ cancellation_policy }}
                </div>
                <div class="w-6 h-6 flex justify-center items-center">
                  <img
                    class="arrowPolicy"
                    src="../assets/images/arrow-prev.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="text-xs mt-4">
                {{ cancellation_policy_full }}
                {{ cancellationLimitStr }}
              </div>
            </div>
            <div
              v-if="paymentDeadLine !== ''"
              class="flex items-center bg-blue-500 text-white text-xs px-4 py-3"
              role="alert"
              style="border-radius: 28px"
            >
              <svg
                class="fill-current w-6 h-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
                ></path>
              </svg>
              <p>
                {{ $t("card_payment_guarantee_part_one") }}
                {{ paymentDeadLine }},
                {{ $t("card_payment_guarantee_part_two") }}
              </p>
            </div>
            <ColorButton
              v-if="redsysData === null && mercadoPagoData === null && stripeData === null && azulData === null"
              :text="getPayText()"
              @click="pay()"
              class="md:mt-8 sm:mt-4"
            >
            </ColorButton>
          </div>
          <Transition
            ><!--storeCard is true, because now, always store the card-->
            <RedsysForm
              v-if="redsysData !== null"
              :redsysData="redsysData"
              @paymentExecuted="paymentExecuted"
              :storeCard="true"
              :onlyStoreCard="onlyStoreCard"
              :cardId="cardId"
              ref="redsysFormRef"
            ></RedsysForm>
          </Transition>
          <Transition>
            <StripeForm
              v-if="stripeData"
              :data="stripeData"
              :user="user"
              @paymentExecuted="paymentExecuted"
              :cardId="cardId"
            ></StripeForm>
          </Transition>
          <Transition>
            <AzulForm
              v-if="azulData"
              :data="azulData"
              :user="user"
              @paymentExecuted="paymentExecuted"
              :cardId="cardId"
            ></AzulForm>
          </Transition>
          <Transition>
            <TransbankForm
              v-if="transbankData"
              :data="transbankData"
              :user="user"
              @paymentExecuted="paymentExecuted"
              :cardId="cardId"
            ></TransbankForm>
          </Transition>
          <Transition>
            <MercadoPagoForm
              v-if="mercadoPagoData !== null"
              :data="mercadoPagoData"
              :user="user"
              @paymentExecuted="paymentExecuted"
              :cardId="cardId"
            ></MercadoPagoForm>
          </Transition>

          <Transition>
            <PopUpLight
              v-if="getCvvPopup"
              @closed="getCvvPopup = false"
              :customClass="'width-xsm'"
            >
              <div class="mt-8">
                <InputText v-model="cvv" :placeholder="`CVV`" />
                <br />
                <div
                  @click="cvvOkClicked()"
                  class="flex justify-center filledButton md:mt-8 sm:mt-4 cursor-pointer sm:w-full"
                >
                  {{ $t("text_continue") }}
                </div>
              </div>
            </PopUpLight>
          </Transition>
        </div>
        <div v-if="show3DLogos" class="flex items-center justify-center">
             <div class="w-24 h-20 mr-4 p-3 br8" >
                <img class="bg-white" alt="visa secure logo" src="../assets/images/cards/visasecureblue.png" />
              </div>
             <div class="w-24 h-20 mr-4 p-3 br8 ">
                <img class="bg-white" alt="mastercard identity check logo" src="../assets/images/cards/mc_idcheck.svg" />
              </div>
          </div>
      </div>
    </div>

    <div
      v-if="saleIsPaid"
      class="flex justify-center flex-col items-center sm:w-full sm:p-6"
    >
      <p class="text-center text-3xl not-italic font-semibold mt-4 mb-4">
        {{ $t("oh_no") }}
      </p>
      <p
        class="text-pink-400 text-center text-lg not-italic font-medium w-4/5 mb-12"
      >
        {{ $t("purchase_was_successful") }}
      </p>

      <router-link to="/">
        <div
          class="buttomError cursor-pointer walletButton flex justify-center items-center p-4"
        >
          {{ $t("back_to_home") }}
        </div>
      </router-link>
    </div>
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderView from "@/components/header/header.vue";
import { customGlobalError } from "@/helpers/errorHelper";
import ColorButton from "@/components/buttons/colorButton.vue";
import RedsysForm from "@/components/paymentGateways/redsysForm.vue";
import MercadoPagoForm from "@/components/paymentGateways/mercadopagoForm.vue";
import StripeForm from "@/components/paymentGateways/stripeForm.vue";
import AzulForm from "@/components/paymentGateways/azulForm.vue";
import TransbankForm from "@/components/paymentGateways/transbankForm.vue";
import { SaleApi } from "@/service/SaleApi";
import { PaymentApi } from "@/service/PaymentApi";
import { Format } from "@/helpers/formatHelper";
import { beautifyDate } from "@/helpers/dateHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import { AppsHelper, callWebviewPaymentCompleteMethod } from "@/helpers/appsHelper";
import store from "../store";
import { AuthorizeSaleRequest, Card } from "models";
import { CardApi } from "@/service/CardApi";
import { SaleStatus, GlobalErrors, BookingType } from "@/enum/constants";
import HistoryBack from "@/components/form/historyBack.vue";
import { encodeString, decodeString } from "@/helpers/dataHelper";
import PopUpLight from "@/components/popup/popupLight.vue";
import InputText from "@/components/form/inputText.vue";
import { CheckoutPayment } from "@/custom-models/checkoutPayment";

export default defineComponent({
  name: "checkoutPaymentView",
  components: {
    HeaderView,
    ColorButton,
    RedsysForm,
    MercadoPagoForm,
    StripeForm,
    AzulForm,
    TransbankForm,
    SvgContainer,
    HistoryBack,
    PopUpLight,
    InputText,
  },
  data() {
    return {
      saleId: "" as string | string[],
      showIntroduceCard: false,      
      paymentData: {} as any,
      redsysData: null,
      mercadoPagoData: null,
      stripeData: null,
      azulData: null,
      transbankData: null,
      openPolicity: false,
      openLegal: true,
      okLegal: false,
      onlyStoreCard: false,
      cardId: "" as string,
      cvv: "",
      getCvvPopup: false,
      cancellationLimitStr: "",
      isWebView: false,
      paymentDeadLine: "",
      cards: [] as Card[],
      saleIsPaid: false,
      user: null,
      errorMsg: null as string | null,
      retryCount: 10,
      show3DLogos: false,
      saleType: null as number | null,
      cancellation_policy: null as string | null,
      cancellation_policy_full: null as string | null,
      showPolicy: true,
      iosWebview: false
    };
  },
  async mounted() {
    let user = "";
    let customerId: string | undefined;
    let customerToken: string | undefined;
    let cancellationLimitDate: string | undefined;
    let jsonData = null;
    this.isWebView = AppsHelper.isAndroidWebView();
    if(this.$route.params.data){
      try {
        jsonData = JSON.parse(decodeString(this.$route.params.data as string)) as CheckoutPayment;
        this.saleId = jsonData.saleId;
        this.saleType = jsonData.saleType;
        
        if (jsonData.cancellationDate) {
          cancellationLimitDate = jsonData.cancellationDate;
        }
        if(jsonData.mobileApp){
          this.iosWebview = jsonData.mobileApp;
          this.isWebView = this.iosWebview;
        }
      } catch (error) {
        this.isWebView ? customGlobalError(this.$t("wrong_url")) : customGlobalError(this.$t("wrong_url"), "/");
      }
      if (//opcionales por que llegan solo de apps
        !Format.IsNull(jsonData?.customerId) &&
        !Format.IsNull(jsonData?.customerToken)
      ) {
        customerId = jsonData?.customerId;
        customerToken = jsonData?.customerToken;
      }
    } else {//mantenemos la versiona anterior con los diferentes parametros hasta que las apps se actualizen
      this.saleId = this.$route.params.saleId;
      if (this.$route.params.cancellationLimitDate !== "null") {
        try {
          cancellationLimitDate = atob(this.$route.params.cancellationLimitDate.toString());
        } catch {
          this.isWebView ? customGlobalError(this.$t("wrong_url")) : customGlobalError(this.$t("wrong_url"), "/");
        }
      }
      if (//opcionales por que llegan solo de apps
        !Format.IsNull(this.$route.params.id) &&
        !Format.IsNull(this.$route.params.token)
      ) {
        customerId = atob(this.$route.params.id as string);
        customerToken = atob(this.$route.params.token as string);
      }
    }
    if (this.saleId === undefined || this.saleId === "") {
      customGlobalError(this.$t("wrong_url"));
      return;
    }
    if(!Format.IsNull(customerId)){
      const webViewUser = { userId: customerId,};
      user = JSON.stringify(webViewUser);
      localStorage.setItem("user", user);
      localStorage.setItem("t", JSON.stringify(customerToken as string));
    } else {
      user = localStorage.getItem("user") as string;
    }
    
    if (Format.IsNull(user)) {
      store.commit("openLogin", {
        allowClose: true,
        goToUrl: this.$route.fullPath,
      });
      return;
    }

    this.user = JSON.parse(user);
    document.body.style.overflowY = "scroll";
    if (window.innerWidth > 768 && !this.isWebView) {
      document.body.style.background = "url(/img/background-test.485cbfa3.png) top 100% center";
      document.body.style.backgroundRepeat = "no-repeat";
    }

    if(this.isWebView){
      const appDiv = document.querySelector('#app') as HTMLElement;
      if(appDiv){
        appDiv.style.background = 'none';
      }
    }

    if (this.$route.query.payment_redirect !== undefined) {
      this.paymentRedirect();
      return;
    }

    this.cancellation_policy = this.$t("cancellation_policy") 
    if(cancellationLimitDate){
      this.cancellationLimitStr = beautifyDate(cancellationLimitDate);
      this.cancellation_policy_full = this.$t("cancellation_policy_full_text");
    }else{
      switch(this.saleType) {
        case BookingType.BOOKING:
          this.cancellation_policy_full = this.$t("not_cancellable");
          break;
        case BookingType.MATCH:
        case BookingType.ACTIVITY:
          this.showPolicy = false;
          break;
      }
    }
  
    const response: any = await SaleApi.findByHash(this.saleId.toString());
    this.paymentData = response.data;
    if(response.data.status && Number(response.data.status.code) == SaleStatus.FULL_PAID){
      customGlobalError(this.$t("purchase_was_successful"), "/");
    }
    const data: any = this.paymentData;
    if (data.paymentProviderData !== undefined) {
      if (data.paymentProviderData.cards && data.paymentProviderData.cards.length > 0) {
        this.cards = data.paymentProviderData.cards;
        if (this.cards.length > 0 && this.cards[0].token !== undefined) {
          this.cardId = this.cards[0].token;
        }
      }
      if (data.paymentProviderData.properties && data.paymentProviderData.properties.paymentDeadline) {
        this.paymentDeadLine = beautifyDate(
          data.paymentProviderData.properties.paymentDeadline
        );
      }
    }

    if (data.status.code === SaleStatus.FULL_PAID) {
      this.saleIsPaid = true;
    }
    this.show3DLogos = data.paymentProviderData.name == "AZUL";
  },
  methods: {
    async pay() {
      const data: any = this.paymentData;
      if (!Format.IsNull(this.cardId)) {
        if (data.paymentProviderData.properties.cvv === "required") {
          this.getCvvPopup = true;
          return;
        }
        await this.payByCard();
        return;
      }
      switch (data.paymentProviderData.name) {
        case "REDSYS":
          this.redsysData = data;
          break;
        case "MERCADOPAGO":
          this.mercadoPagoData = data;
          break;
        case "MERCADOPAGOCL":
          this.mercadoPagoData = data;
          break;
        case "STRIPE":
        case "STRIPEUS":
          this.stripeData = data;
          break;
        case "AZUL":
          this.azulData = data;
        break;
        case "TRANSBANK":
          this.transbankData = data;
        break;
      }
    },
    async payByCard() {
      let data: AuthorizeSaleRequest = {
        saleId: this.saleId as string,
        cardId: this.cardId,
        cvv: this.cvv,
      };
      /*
      const redsysFormRef = this.$refs.redsysFormRef as InstanceType<typeof RedsysForm>;
        let data: AuthorizeSaleRequest = {
        saleId: this.saleId as string,
        cardId: this.cardId,
        cvv: this.cvv,
      };
      redsysFormRef.initPayment(data);
      */ //all comented card payment using child function
      try {
        store.commit("setLoading", true);
        await SaleApi.authorize(data);
        this.paymentExecuted({ success: true });
      } catch (error: any) {
        const paymentError = this.buildPaymentError(error);
        this.paymentExecuted(paymentError);
      } finally {
        store.commit("setLoading", false);
      }      
    },
    buildPaymentError(error: any) {
      const paymentErrorMsg = this.$t("payment_platform_operation_cannot_be_completed");
      return {
            success: false,
            status: error.response.status,
            error:
              (error &&
                error.response &&
                error.response.data.messages[0] === null) ||
              error.response.data.messages[0] === undefined
                ? paymentErrorMsg
                : error.response.data.messages[0],
          }
    },
    paymentExecuted(result: any) {
      callWebviewPaymentCompleteMethod(result.success, this.errorMsg as string);
      if (result.success === true) {
        return this.$router.push("/checkout-ok/" + this.saleId);
      }
      switch (Number(result.status)) {
        case -1:
        case GlobalErrors.ERROR_500:
          this.errorMsg = encodeString(this.$t("payment_platform_operation_cannot_be_completed"));
          break;
        case GlobalErrors.ERROR_400:
          this.errorMsg = encodeString(this.$t("does_no_accept_this_type_of_card"));
          break;
        case GlobalErrors.ERROR_402:
          this.errorMsg = encodeString(this.$t("insert_card_info"));
          break;
        case GlobalErrors.ERROR_401:
          this.errorMsg = encodeString(this.$t("pay_declined"));
          break;
        case GlobalErrors.ERROR_499:
          this.errorMsg = encodeString(this.$t("pay_cancelled"));
          break;
      }
      return this.goPayError(result);
    },
    async authorize(data: AuthorizeSaleRequest) {
      try {
        await SaleApi.authorize(data);
        this.paymentExecuted({ success: true });
      } catch (error: any) {
        this.paymentExecuted(this.buildPaymentError(error));
      }
    },
    async paymentRedirect() {      
      let type = this.$route.query.payment_redirect;
      let data: any;
      let response;
      switch (type) {
        case "stripe":
          response = await PaymentApi.getStripePaymentIntentStatus(
            this.$route.query.payment_intent as string
          );
          data = response.data
          break;
        default:
          data = { ok: true }
          break;
      }

      if (data == null) {
        this.paymentExecuted({ success: false, status: GlobalErrors.ERROR_500 });
        return;
      }

      if(data.ok === true && data.completed === false) {
        const authdata: AuthorizeSaleRequest = {
              saleId: this.saleId as string,
              storeCard: true,
              operationId: this.$route.query.payment_intent as string
            };
        await this.authorize(authdata);
        return;
      }

      if (data.retry === true && this.retryCount > 0) {
        this.retryCount--;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.paymentRedirect();
        return;
      }            

      if (data.completed === true) {
        this.paymentExecuted({ success: true });
        return;
      }
      this.paymentExecuted({ success: false, status: GlobalErrors.ERROR_500 });
    },
    getPayText() {
      let text = this.$t("pay");
      if (this.cards.length <= 0) {
        text = this.$t("add_card_and_pay");
      }
      const data: any = this.paymentData;
      if(!Format.IsNull(data?.grossAmountStr)){
        return(text + " " + data.grossAmountStr) 
      }
      return (
        text +
        " " +
        Format.formatCurrency(
          data.amountData.grossAmount,
          data.amountData.currencyCode,
          data.amountData.locale
        )
      );
    },
    getLast4Digits(card: string) {
      if (card.length < 4) {
        return card;
      }
      return card.slice(-4);
    },
    async deleteCard(token: string, e: any) {
      e.stopPropagation();
      e.preventDefault();
      await CardApi.deleteByToken(token);
      this.cardId = "";
      this.cards = this.cards.filter((card) => card.token !== token);
      if (this.cards.length > 0 && this.cards[0].token !== undefined) {
        this.cardId = this.cards[0].token;
      }
      this.getPayText();
    },
    cvvOkClicked() {
      if (this.cvv !== "") {
        this.payByCard();
        this.getCvvPopup = false;
      }
    },
    goPayError(result: any) {
      callWebviewPaymentCompleteMethod(result.success, this.errorMsg as string);
      //old params, until all apps go with new params
      let cancellationLimitDate = "noCancelDate";
      let saleId = "";
      if(this.$route.params.cancellationLimitDate){
        cancellationLimitDate = this.$route.params.cancellationLimitDate as string;
      }
      if(this.$route.params.cancellationLimitDate){
        saleId = this.$route.params.saleId as string;
      }
      //new params
      if(this.$route.params.data){
        try {
          const jsonData = JSON.parse(decodeString(this.$route.params.data as string)) as CheckoutPayment;
          saleId = jsonData.saleId;
          
          if (jsonData.cancellationDate) {
            cancellationLimitDate = jsonData.cancellationDate;
          }
        } catch (error) {
          //customGlobalError(this.$t("wrong_url"), "/");
        }
      }
     
      let url = "/pay-error/" +
          saleId +
          "/" +
          cancellationLimitDate +
          "/" +
          this.errorMsg;
      //saltamos se el navigation guard del index para permitir la navegacion
      store.state.globalErrorIsOpen = false;          
      this.$router.push(url);
    },
  },
});
</script>
<style scoped>
.filledButton {
  width: initial;
}
.check {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #586577;
}

.referenceSearch {
  opacity: 0.8;
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  padding-left: 20px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.modalPayment {
  width: 564px;
  /* height: 797px; */
  /* border-radius: 16px;
  background: var(--background-color-box);
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
}
@media (max-width: 767px) {
  /* .modalPayment {
    width: 90%;
    height: 90%;
    background-color: transparent;
  } */
}
.logoWrapper {
  background-color: #1c2a41;
  border-radius: 8px;
  margin: 0 16px;
}

.containerRowWallet {
  background: linear-gradient(
    107deg,
    rgba(6, 27, 46, 0) 0%,
    rgba(7, 106, 227, 0.13) 100%
  );
  border-radius: 16px;
  padding: 16px 0;
}

.policy {
  border-bottom: 1px solid rgba(255, 255, 255, 0.158);
}

.arrowPolicy {
  transform: rotate(-90deg);
}

.modalPayment .check {
  border-radius: 8px;
  overflow: hidden;
}

.wrapperBottomWallet {
  bottom: 30px;
}

.modalNewPayment .referenceSearch {
  padding-left: 24px;
}

.modalNewPayment .seach-icon {
  left: auto;
  right: 20px;
}

.newPaymentText {
  color: white;
  border-radius: 16px;
  background: rgba(129, 170, 237, 0.1);
  opacity: 0.7;
}

.modalNewPayment {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 464px;
  background-color: var(--background-color-box);
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  z-index: 9;
  border-radius: 16px;
}

@media (max-width: 767px) {
  .modalNewPayment {
    transform: translate(-50%, 0%);
    width: 100%;
    background-color: transp;
  }
  .check {
    height: 20px !important;
    width: 20px !important;
  }
}

.modalPayment .seleccionado::after {
  content: "";
  background-image: url("../assets/images/plCheck.svg");
  height: 25px;
  width: 25px;
  left: 50%;
  top: 50%;
  position: absolute;
  background-size: 100%;
  transform: translate(-50%, -50%);
}

@media (min-width: 767px) {
  .modalPayment .seleccionado::after {
    height: 38px;
    width: 38px;
  }
}
.policy {
  height: 40px;
  overflow: hidden;
  transition: 0.5s;
}
.openPolicity {
  height: 160px !important;
  transition: 0.5s;
}

.checkoutPolicy {
  border-bottom: 1px solid rgba(255, 255, 255, 0.158);
  height: 40px;
  transition: 0.4s;
  overflow: hidden;
}

.cardOption {
  border: 1px solid transparent;
  border-radius: 16px;
}

.deleteCard {
  background: #2b4164;
}
</style>