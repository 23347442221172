import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b331ba5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-8" }
const _hoisted_2 = ["action"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["value"]
const _hoisted_17 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      ref: "paymentForm",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
      action: _ctx.data.paymentProviderData.paymentPageUrl,
      method: "post",
      id: "paymentForm",
      name: "paymentForm"
    }, [
      _createElementVNode("input", {
        type: "hidden",
        name: "MerchantId",
        value: _ctx.data.paymentProviderData.paymentPageParameters.MerchantId
      }, null, 8, _hoisted_3),
      _createElementVNode("input", {
        type: "hidden",
        name: "MerchantName",
        value: _ctx.data.paymentProviderData.paymentPageParameters.MerchantName
      }, null, 8, _hoisted_4),
      _createElementVNode("input", {
        type: "hidden",
        name: "MerchantType",
        value: _ctx.data.paymentProviderData.paymentPageParameters.MerchantType
      }, null, 8, _hoisted_5),
      _createElementVNode("input", {
        type: "hidden",
        name: "CurrencyCode",
        value: _ctx.data.paymentProviderData.paymentPageParameters.CurrencyCode
      }, null, 8, _hoisted_6),
      _createElementVNode("input", {
        type: "hidden",
        name: "OrderNumber",
        value: _ctx.data.paymentProviderData.paymentPageParameters.OrderNumber
      }, null, 8, _hoisted_7),
      _createElementVNode("input", {
        type: "hidden",
        name: "Amount",
        value: _ctx.data.paymentProviderData.paymentPageParameters.Amount
      }, null, 8, _hoisted_8),
      _createElementVNode("input", {
        type: "hidden",
        name: "ITBIS",
        value: _ctx.data.paymentProviderData.paymentPageParameters.ITBIS
      }, null, 8, _hoisted_9),
      _createElementVNode("input", {
        type: "hidden",
        name: "ApprovedUrl",
        value: _ctx.data.paymentProviderData.paymentPageParameters.ApprovedUrl
      }, null, 8, _hoisted_10),
      _createElementVNode("input", {
        type: "hidden",
        name: "DeclinedUrl",
        value: _ctx.data.paymentProviderData.paymentPageParameters.DeclinedUrl
      }, null, 8, _hoisted_11),
      _createElementVNode("input", {
        type: "hidden",
        name: "CancelUrl",
        value: _ctx.data.paymentProviderData.paymentPageParameters.CancelUrl
      }, null, 8, _hoisted_12),
      _createElementVNode("input", {
        type: "hidden",
        name: "UseCustomField1",
        value: _ctx.data.paymentProviderData.paymentPageParameters.UseCustomField1
      }, null, 8, _hoisted_13),
      _createElementVNode("input", {
        type: "hidden",
        name: "UseCustomField2",
        value: _ctx.data.paymentProviderData.paymentPageParameters.UseCustomField2
      }, null, 8, _hoisted_14),
      _createElementVNode("input", {
        type: "hidden",
        name: "AuthHash",
        value: _ctx.data.paymentProviderData.paymentPageParameters.AuthHash
      }, null, 8, _hoisted_15),
      _createElementVNode("input", {
        type: "hidden",
        name: "ShowTransactionResult",
        value: _ctx.data.paymentProviderData.paymentPageParameters.ShowTransactionResult
      }, null, 8, _hoisted_16),
      _createElementVNode("input", {
        type: "hidden",
        name: "SaveToDataVault",
        value: _ctx.data.paymentProviderData.paymentPageParameters.SaveToDataVault
      }, null, 8, _hoisted_17)
    ], 40, _hoisted_2)
  ]))
}