<template>
  <div class="md:flex containerMapsResults md:pb-8">
    <div class="CalendarMaps md:mt-9 md:p-8 sm:mt-4">
      <div class="h-full">
        <div class="sm:p-5 MapsText relative sm:flex sm:flex-col">
          <span>{{ $t("club_info") }}</span>
          <div class="mt-3 flex">
            <div class="flex mr-4 sm:text-sm" v-if="hasPadel()">
              <span class="mr-2 items-center flex">
                <SvgContainer name="homeModalPadelIcon" />
              </span>
              {{ $t("padel") }}
            </div>
            <div class="flex sm:text-sm" v-if="hasTenis()">
              <span class="items-center flex mr-2">
                <SvgContainer name="homeModalTenisIcon" />
              </span>
              <span class="mr-2">{{ $t("tennis") }}</span>
            </div>
            <div class="flex sm:text-sm" v-if="hasPickle()">
              <span class="items-center flex mr-2">
                <SvgContainer name="homeModalPickleIcon" />
              </span>
              <span class="mr-2">{{ $t("pickleball") }}</span>
            </div>
            <div class="flex sm:text-sm" v-if="hasSoccer()">
              <span class="items-center flex mr-2">
                <SvgContainer name="homeModalSoccerIcon" />
              </span>
              <span class="mr-2">{{ $t("soccer") }}</span>
            </div>
          </div>
          <div
            class="flex sm:justify-center sm:mt-5 md:absolute IconsMaps top-0 right-0"
          >
            <span
              @click="openGoogleMaps(9)"
              class="mr-8 flex justify-center flex-col items-center text-center cursor-pointer"
            >
              <SvgContainer name="directionRightIcon" />
              <span class="md:hidden text-xs">{{ $t("directions") }}</span>
            </span>

            <span
              @click="showPhoneModal = true"
              class="flex justify-center flex-col items-center text-center cursor-pointer"
            >
              <SvgContainer name="roundedTelfIcon" />
              <span class="md:hidden text-xs">{{ $t("call") }}</span>
            </span>
          </div>
        </div>
        <div class="MapResuls h-full overflow-hidden md:mt-8 sm:mt-2">
          <div id="map"></div>
        </div>
      </div>
    </div>
    <div class="CalendarMaps md:mt-9 md:p-8 sm:p-5 sm:pb-9">
      <div class="h-full">
        <div class="text-lg">{{ $t("opening_hours") }}</div>
        <div class="mt-5 OpeningOpacityText flex justify-between pb-5 sm:text-sm" :class="{ OpeningResults: !isLinked }">
          <span>{{ $t("opening_hours") }}</span>
          <span>{{ tenantInfo.tenant.openHours }}</span>
        </div>

        <LinkPrivate v-if="!isLinked"
          :booking="tenantInfo"
          @linkClosed="showPopLinkPrivate = false"
        />
      </div>
    </div>

    <Transition>
      <PopUpLight v-if="showPhoneModal" @closed="showPhoneModal = false">
        <div
          class="mt-5 OpeningOpacityText flex justify-evenly pb-5 OpeningResults sm:text-sm"
        >
          <span> {{ $t("phone") }} </span>
          <span>
            <a :href="`tel:${tenantInfo.tenant.phone}`">{{
              tenantInfo.tenant.phone
            }}</a>
          </span>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="showPopLinkPrivate"
        @closed="showPopLinkPrivate = false"
      >
        <div class="mt-5 flex justify-evenly sm:text-sm">
          <span> {{ $t("the_club_is_private") }} </span>
        </div>
        <div class="flex justify-evenly sm:text-sm">
          <LinkPrivate
            :booking="tenantInfo"
            @linkClosed="showPopLinkPrivate = false"
          />
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
<script>
import SvgContainer from "@/views/SvgContainer.vue";
import { Sport } from "../../enum/constants";
import PopUpLight from "../popup/popupLight.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import LinkPrivate from "@/components/form/linkPrivate.vue";
import CustomerHelper from "@/helpers/customerHelper";

export default {
  name: "homeBooking",
  data() {
    return {
      showPhoneModal: false,
      showPopLinkAccount: false,
      showPopLinkPrivate: false,
      isLinked: true
    };
  },
  components: {
    SvgContainer,
    PopUpLight,
    LinkPrivate,
  },
  props: ["tenantInfo", "showPrivate"],
  async mounted() {
    this.isLinked = await CustomerHelper.customerIsLinked(this.tenantInfo.tenant.id);
    this.loadMap();
    this.showPhoneModal = false;
    this.fixIphoneHeight();
    if (this.showPrivate) {
      this.showPopLinkPrivate = this.showPrivate;
    }
  },
  methods: {
    loadMap() {
      var mapContainer = document.getElementById("map");
      if (mapContainer == null) {
        return;
      }

      var mapUrl =
        "https://www.google.com/maps/embed/v1/place?key=" +
        process.env.VUE_APP_GOOGLE_API_KEY +
        "&q=" +
        this.tenantInfo.tenant.cordX +
        "," +
        this.tenantInfo.tenant.cordY +
        "";

      mapContainer.innerHTML =
        '<iframe frameborder="0" style="border:0" src="' +
        mapUrl +
        '" allowfullscreen></iframe>';
    },
    hasPadel() {
      return (
        this.tenantInfo.tenant.sports.find((y) => y === Sport.PADEL) !==
        undefined
      );
    },
    hasTenis() {
      return (
        this.tenantInfo.tenant.sports.find((y) => y === Sport.TENIS) !==
        undefined
      );
    },
    hasPickle() {
      return (
        this.tenantInfo.tenant.sports.find((y) => y === Sport.PICKLEBALL) !==
        undefined
      );
    },
    hasSoccer() {
      return (
        this.tenantInfo.tenant.sports.find((y) => y === Sport.SOCCER) !==
        undefined
      );
    },
    openGoogleMaps() {
      const jsonData = {
        locationTapped: {
          cordX: this.tenantInfo.tenant.cordX,
          cordY: this.tenantInfo.tenant.cordY,
          name: this.tenantInfo.tenant.name,
        },
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      window.open(
        `https://www.google.com/maps/place/${this.tenantInfo.tenant.cordX},${this.tenantInfo.tenant.cordY}`
      );
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const searchContainer = document.querySelector(".containerMapsResults");
        if (searchContainer) {
          searchContainer.classList.add("pb-100");
        }
      }
    },
  },
};
</script>