<template>
  <div>
    <div id="chat" class="">
      <span v-if="!chatLogged">Loading Chat</span>
      <span v-else><CometChatUI /></span>
    </div>
  </div>
</template>
<script type="ts">
import { CometChatUI } from "./cometchat-pro-vue-ui-kit/CometChatWorkspace/src";
import { CometChat } from "@cometchat-pro/chat";
import { Format } from "@/helpers/formatHelper";
import store from "../../store";
import { ChatApi } from "@/service/ChatApi";
import { mapState } from "vuex";
import { customGlobalError } from "@/helpers/errorHelper";

const authKey = "c9635b4ede33da5f53fc75faa27065cbb1f9ff1a";

export default {
  name: "TaykusChat",
  components: {
    CometChatUI,
  },
  props: {
    enableButtons: {
      type: Boolean,
      default: true,
    },
    bookingId: {
      type: String,
    },
  },
  data() {
    return {
      chatLogged: false,
    };
  },
  computed: {
    ...mapState(["getInChatConversations"]),
  },
  watch: {
    getInChatConversations() {
      document.getElementById(this.bookingId).click();
    },
  },
  async mounted() {
    await this.loginChat();
  },
  beforeUnmount() {
    store.commit("getInChatConversations", false);
  },
  methods: {
    async loginChat() {
      const userJson = localStorage.getItem("user");
      let user = null;
      if (Format.IsNull(userJson)) {
        customGlobalError(
          this.$t("sorry_must_be_logged_to_acces_chat_service")
        );
      }
      user = JSON.parse(userJson);
      const uid = user.id;
      if (Format.IsNull(this.bookingId)) {
        customGlobalError(this.$t("wrong_url"), "/");
      }
      await ChatApi.createUser(this.bookingId);
      await CometChat.login(uid, authKey).then(
        (user) => {
          this.chatLogged = true;
        },
        (error) => {
          customGlobalError(
            this.$t("oops_technical_problems_try_again_in_a_few_minutes")
          );
        }
      );
    },
    async logoutChat() {
      await CometChat.logout().then(
        (user) => {
          this.chatLogged = false;
        },
        (error) => {
          customGlobalError(
            this.$t("oops_technical_problems_try_again_in_a_few_minutes")
          );
        }
      );
    },
  },
};
</script>
<style>
#chat {
  height: 800px;
  margin-top: 40px;
}

.chats__wrapper div,
.unified__sidebar {
  border: none !important;
}
.chat__item--hover {
  border-radius: 20px;
}
.cometchat__main__chat div {
  border-radius: 20px;
  margin-bottom: 5px;
  border: none !important;
}
</style>