<template>
<main>
    <h3 class="title-onboarding not-italic font-medium text-3xl text-center text-white">
        {{ $t('what_sport_do_you_play') }}
    </h3>
</main>
<Scroll></Scroll>
</template>

<script>
import Scroll from './scrollSnippet.vue'

export default {
  name: 'usuallyGame',
  components: {
    Scroll,
  },
  data() {
    return {
      error: ''
    };
  },
  methods: {
    clear() {
      this.error = '';
    }
  }

};
</script>

<style>
</style>
