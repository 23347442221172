<template>
<main >
  <h3 class="title-onboarding not-italic font-medium text-3xl text-center text-white">
      {{ $t('what_do_you_usually_do') }}
  </h3>
  <span class="subtitle-onboarding not-italic font-normal text-sm text-center block">
      {{ $t('select_only_one_option') }}
  </span>
  <div class="buttons">
    <label>
        <button
      class="option-play flex items-center"
      v-for="(button, index) in buttons"
      :key="index"
      :class="{ label_seleccionado: index === action }"
      @click="updateValue(index)"
    >
      <input
        type="checkbox"
        :class="{ check_seleccionado: index === action }"
      />
      {{ button }}
    </button>
    </label>
  </div>
</main>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Category } from "../../enum/constants";

export default {
  name: 'usuallyDo',
  props: {

  },
  setup() {
    const store = useStore();
    const action = ref(Category.BOOKING);
    
    const updateValue = (index) => {
      action.value = index;
      store.commit('setPreferencesAction', index);
    };
    
    //watch the store state changes and update the component variables accordingly
    watch(() => store.state.preferences.action, (newVal) => {
    action.value = newVal;
    });
    
    //Initialize the component variables with the store state on component mount
    onMounted(() => {
    action.value = store.state.preferences.action;
    });
    return {
      action,
      updateValue
    };
  },
  
  data() {
    return {
      buttons: [ this.$t('booking'),this.$t('matches') ],
      selected: 0,
      error: ''
    };
  },
  methods: {
    clear() {
      this.error = '';
    }
  }

};
</script>

<style>
.label_seleccionado {
    background: rgba(26, 192, 234, 0.05);
    box-shadow: inset 0px 0px 24px rgba(26, 192, 234, 0.5);
    backdrop-filter: blur(16px);
    border-radius: 16px;
}

</style>
