import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-8 br16 p-6" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = {
  key: 0,
  class: "mt-12 br16 p-4 overflow-y-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center mt-12 br16 altBackground p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchAvailability = _resolveComponent("MatchAvailability")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("my_matches")), 1)
    ]),
    (_ctx.result && _ctx.result.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", null, [
            _createVNode(_component_MatchAvailability, {
              matches: _ctx.result,
              fromRouteName: "view",
              onMatchSelected: _ctx.handleMatchSelected,
              onBookingCancelled: _ctx.handleBookingCancelled
            }, null, 8, ["matches", "onMatchSelected", "onBookingCancelled"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("dont_have_any_match")), 1)
        ]))
  ]))
}