<template>
  <div class="modal-light-container backdrop-blur-lg">
    <div class="modal-light-popup relative" :class="customClass">
      <span v-if="showClose" class="close" @click="closed($event)">×</span>
      <div>
        <div class="modal-light-body">
          <main>
            <slot></slot>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PopUpLight",
  emits: ["ok", "closed"],
  components: {},
  props: {
    errorMsg: String,
    customClass: {
      type: String,
      default: 'width-sm',
    },
    showClose: {
      type: Boolean,
      required:false,
      default: true, 
    },
  },
  methods: {
    ok(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("ok");
    },

    closed(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("closed");
    },
  },
});
</script>

<style scoped>
.modal-light-container {
  /*display: none;  Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1040;
  /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  /* Enable scroll if needed */
  overflow: auto;
  overflow-x: hidden;
  /*background-color: #00061b;*/
  /* Fallback color */
  /* background-color: #11111111; */
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-light-popup {
  margin: auto;
  padding: 20px;
  box-shadow: 2px 2px 10px #33333333;
  width: fit-content;
  height: fit-content !important;
  margin-top: 20%;
  text-align: center;
  border-radius: 24px;
  background: var(--background-color);
  background-repeat: no-repeat;
  background-size: contain;
  /* overflow: scroll;
  overflow-x: hidden; */
}
@media (min-width: 767px) {
  .modal-light-popup {
    margin-top: 0px;
  }
  .width-xsm{
  width: 280px;
  }
  .width-sm{
  width: 480px;
  }
  .width-md{
    width: 640px;
  }
  .width-lg{
    width: 840px;
  }
  .width-xl{
    width: 1200px;
  }
}
.container-form {
  background: #1f304945;
  border-radius: 14px;
}
.modal-light-popup h2 {
  font-weight: 500;
  font-size: 32px;
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-align: left;
  margin: 40px 0px 0px 16px;
}

.modal-light-popup p {
  text-align: left;
  font-size: 12px;
  color: var(--main-color) !important;
  opacity: 0.7;
  margin: 0px 0px 0px 20px;
}

/* The Close Button */
.close {
  color: var(--main-color) !important;
  float: right;
  font-size: 38px;
  font-weight: bold;
  top: 8px;
  position: absolute;
  right: 20px;
}
@media (max-width: 768px) {
  .modal-light-popup {
    margin-top: 20%;
    width: 100%;
  }
}
@media (max-width: 650px) {
  .modal-light-body {
    margin: 0px;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .modal-light-popup {
    margin: 0px;
  }
  .modal-light-container {
    padding: 0px;
    overflow: hidden;
    overflow-x: hidden;
  }
}
@media (max-width: 320px) {
  .modal-light-popup {
    margin: 0px;
  }
  .modal-light-container {
    padding: 0px;
    overflow: auto;
    overflow-x: hidden;
  }
}
</style>