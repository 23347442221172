<template>
 <div class="wrapper-scroll-js" ref="wrapper">
    <div class="container-scroll-js" ref="container">
        <div class="content-scroll-js flex" ref="content">
            <div class="container-hours flex mt-2">
                <div class="cursor-pointer" @click="setActiveOption(this.PADEL)" :class="{ label_seleccionado: isActiveOption(this.PADEL) }">
                    <SvgContainer name="scrollSnippedPadelIcon" /><h1>{{ $t("padel") }}</h1>
                </div>
                <div class="cursor-pointer" @click="setActiveOption(this.TENIS)" :class="{ label_seleccionado: isActiveOption(this.TENIS) }">
                    <SvgContainer name="scrollSnippedTennisIcon" /><h1>{{ $t("tennis") }}</h1>
                </div>
                <div class="cursor-pointer" @click="setActiveOption(this.PICKLEBALL)" :class="{ label_seleccionado: isActiveOption(this.PICKLEBALL) }">
                    <SvgContainer name="scrollSnippedPickerBallIcon" /><h1>{{ $t("pickleball") }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
  
<script>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Sport } from "../../enum/constants";
import SvgContainer from '@/views/SvgContainer.vue';

export default {
    components: {
        SvgContainer
    },
    setup() {
        const store = useStore();
        const selected = ref(Sport.PADEL);

        const setActiveOption = (optionIndex) => {
            selected.value = optionIndex;
            store.commit('setPreferencesSport', optionIndex);
        };

        const isActiveOption = (optionIndex) => {
            return selected.value === optionIndex;
        };

        // Watch the store state changes and update the component variables accordingly
        watch(() => store.state.preferences.sport, (newVal) => {
            selected.value = newVal;
        });

        //Initialize the component variables with the store state on component mount
        onMounted(() => {
            const sport = store.state.preferences.sport
            ? store.state.preferences.sport
            : Sport.PADEL;
            selected.value = sport;
            store.commit('setPreferencesSport', sport);
        });

        return {
            setActiveOption,
            isActiveOption,
        };
    },
    mounted() {
        this.installScrollShadow();
        this.installDragScrollX();
    },
    data() {
        return {
            PADEL: Sport.PADEL,
            TENIS: Sport.TENIS,
            PICKLEBALL: Sport.PICKLEBALL
        };
    },
    methods: {
        installScrollShadow() {
            const wrapper = this.$refs.wrapper;
            const container = this.$refs.container;
            const content = this.$refs.content;

            const scrollShadowHorizontal = () => {
                const containerSize = Math.round(container.offsetWidth);
                const contentSize = Math.round(content.offsetWidth);
                const scrollLeft = Math.round(container.scrollLeft + containerSize);

                if (container.scrollLeft > 6) {
                    wrapper.classList.add('scroll-left');
                } else {
                    wrapper.classList.remove('scroll-left');
                }

                if (scrollLeft >= contentSize || contentSize <= containerSize) {
                    wrapper.classList.remove('scroll-right');
                } else {
                    wrapper.classList.add('scroll-right');
                }
            };

            container.addEventListener('scroll', scrollShadowHorizontal);
            window.addEventListener('resize', scrollShadowHorizontal);

            scrollShadowHorizontal();
        },
        installDragScrollX() {
            const container = this.$refs.container;

            let mouseDown = false;
            let startX, scrollLeft;
            let touchStartX = 0;
            let isDragging = false;

            const startDragging = (e) => {
                mouseDown = true;
                startX = e.pageX - container.offsetLeft;
                scrollLeft = container.scrollLeft;
            };

            const stopDragging = () => {
                mouseDown = false;
            };

            container.addEventListener('mousemove', (e) => {
                e.preventDefault();
                if (!mouseDown) return;

                const x = e.pageX - container.offsetLeft;
                const scroll = x - startX;
                container.scrollLeft = scrollLeft - scroll;
            });

            container.addEventListener('mousedown', startDragging, false);
            container.addEventListener('mouseup', stopDragging, false);
            container.addEventListener('mouseleave', stopDragging, false);

            container.addEventListener('touchstart', (e) => {
                const touch = e.touches[0];
                touchStartX = touch.clientX;
                scrollLeft = container.scrollLeft;
                isDragging = true;
            });

            container.addEventListener('touchmove', (e) => {
                if (!isDragging) return;
                const touch = e.touches[0];
                const touchX = touch.clientX;
                const deltaX = touchX - touchStartX;
                container.scrollLeft = scrollLeft - deltaX;
            });

            container.addEventListener('touchend', () => {
                isDragging = false;
            });

            container.addEventListener('touchcancel', () => {
                isDragging = false;
            });
        },
    },
};
</script>
<style scoped>
h1{
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
}
.container-scroll-js
{}
.container-scroll-js::-webkit-scrollbar {
    width: 1px!important; /* Ancho de la barra de desplazamiento */
    height: 0px!important;
  }
  
  /* Estilo para el riel de la barra de desplazamiento */
  .container-scroll-js::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo del riel */
  }
  
  /* Estilo para el pulgar de la barra de desplazamiento */
  .container-scroll-js::-webkit-scrollbar-thumb {
    background-color: var(--color1); /* Color del pulgar */
  }
</style>