<template>
  <!-- <div @click="isOpen = true" class="menuWrapper p-3 br100 cursor-pointer">
    <img src="../assets/images/GlobalMenu.svg" alt="" />
  </div>
  <div
    @click="isOpen = false"
    v-if="isOpen"
    class="backgroundMenu fixed w-full h-full"
  ></div>
  <div
    @click="isOpen = false"
    v-if="isOpen"
    class="backgroundMenuOpacity fixed w-full h-full"
  ></div> -->

  <Transition>
    <PopUpLight v-if="open === true" @closed="open = false">
      <div class="relative text-center mt-10 mb-6">
        <!-- <p class="text-base">Options</p> -->
      </div>
      <router-link to="/user">
        <div class="menuRow flex justify-between flex p-4 cursor-pointer">
          <div class="flex">
            <img src="../assets/images/User.svg" alt="" />
            <p class="ml-4 text-sm">{{ $t("my_profile") }}</p>
          </div>
          <!-- <img class="rotate" src="../assets/images/arrow-prev.svg" alt="" /> -->
        </div>
      </router-link>
      <!-- <div class="menuRow flex justify-between flex p-4 cursor-pointer">
        <div class="flex">
          <img src="../assets/images/heart.svg" alt="" />
          <p class="ml-4 text-sm">Favs</p>
        </div>
        <img class="rotate" src="../assets/images/arrow-prev.svg" alt="" />
      </div> -->
      <!-- <div class="menuRow flex justify-between flex p-4 cursor-pointer">
                    <div class="flex ">
                        <img src="../assets/images/Setting.svg" alt="">
                        <p class="ml-4 text-sm ">My Profile</p>
                    </div>
                    <img class="rotate " src="../assets/images/arrow-prev.svg" alt="">
                </div> -->
      <div
        class="menuRow text-pink-400 flex justify-between flex p-4 cursor-pointer"
        @click="logout()"
      >
        <div class="flex">
          <img src="../assets/images/LogOut.svg" alt="" />
          <p class="ml-4 text-sm">{{ $t("logout") }}</p>
        </div>
        <!-- <img class="rotate" src="../assets/images/arrow-prev.svg" alt="" /> -->
      </div>
    </PopUpLight>
  </Transition>
</template>
  
<script lang="ts">
import { defineComponent } from "vue";
import store from "../store";
import PopUpLight from "../components/popup/popupLight.vue";
import CustomerHelper from "@/helpers/customerHelper";

export default defineComponent({
  data() {
    return {
      open: false,
    };
  },
  props: ["isOpen"],
  emits: ["refresh"],
  components: {
    PopUpLight,
  },
  mounted() {
    this.open = this.isOpen;
  },
  methods: {
    logout() {
      CustomerHelper.logout(store);
      this.open = false;
      this.$emit("refresh");
    },
  },
});
</script>

<style scoped>
.globalMenu {
  z-index: 9;
  background-color: var(--background-color-box);
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  bottom: 0;
  left: 0;
}
@media (min-width: 767px) {
  .globalMenu {
    width: 375px;
    background-color: var(--background-color-box);
    box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.outButton {
  color: #f09a99;
}
.rotate {
  transform: rotate(180deg);
}
.closeMenu {
  right: 0;
  top: 0;
}
.backgroundMenuOpacity {
  top: 0;
  left: 0;
  z-index: 8;
  background-color: black;
  opacity: 0.4;
}
.backgroundMenu {
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
  z-index: 8;
}
.menuWrapper {
  height: fit-content;
  border-radius: 32px;
  background: linear-gradient(
        var(--background-color-box),
        var(--background-color-box)
      )
      padding-box,
    linear-gradient(
        60deg,
        rgba(255, 255, 255, 0.247),
        var(--background-color-box)
      )
      border-box;
  background-color: #081427;
  border: 1px solid transparent;
}
</style>