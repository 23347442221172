import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import BookingView from '../views/BookingView.vue';
import SearchResultsView from '../views/SearchResultsView.vue';
import CheckoutView from '../views/CheckoutView.vue';
import PlayersView from '../views/BookingCreationView.vue';
import CheckoutPaymentView from '../views/CheckoutPaymentView.vue';
import TransbankValidationView from '../views/TransbankValidationView.vue';
import PreloadView from '../views/PreloadView.vue';
import CheckoutOkView from '../views/CheckoutOkView.vue';
import ErrorPage from '../views/404Page.vue';
import BookingJoin from '../views/BookingJoinView.vue';
import BookingResults from '../views/BookingResultsView.vue';
import payError from '../views/PayError.vue';
import LegalView from '../views/LegalView.vue';
import UserProfileView from '../views/UserProfileView.vue';
import RemoveAccountView from '../views/RemoveAccountView.vue';
import SuportView from '../views/SupportView.vue';
import MatchCreationView from '../views/MatchCreationView.vue';
import ChatView from '../views/ChatView.vue';
import linkAccountResponse from '../views/linkAccountResponse.vue';
import store from '@/store';
import DisconnectErrorPage from '../views/DisconnectErrorPage.vue';
import PaymentBridge from '../views/PaymentBridge.vue';
import IframeRedirect from '../views/IframeRedirect.vue';
import LevelingGender from '../views/LevelingView.vue';
import PaymentRedirect from '../views/PaymentRedirect.vue';
import Azul from '../views/Azul.vue';
import ActivityJoin from '../views/ActivityJoinView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search-results',
    name: 'search-results',
    props: true,
    component: SearchResultsView
  },
  {
    path: '/booking',
    name: 'booking',
    props: true,
    component: BookingView,
  },
  {
    path: '/players',
    name: 'players',
    props: true,
    component: PlayersView,
  },
  {
    path: '/checkout',
    name: 'checkoutView',
    props: true,
    component: CheckoutView,
  },
  {
    path: '/checkout-payment/:saleId/:cancellationLimitDate/:token?/:id?',
    name: 'checkoutPaymentView',
    props: true,
    component: CheckoutPaymentView,
  },
  {
    path: '/checkout-payment/:data',
    name: 'checkoutPaymentViewCoded',
    props: true,
    component: CheckoutPaymentView,
  },
  {
    path: '/preload/:saleId/:cancellationLimitDate',
    name: '/preload',
    component: PreloadView
  },
  {
    path: '/checkout-ok/:saleId', //could be the bookingid also but it has to be stored in localstorage
    name: 'checkoutOkView',
    props: true,
    component: CheckoutOkView,
  },
  {
    path: '/booking-join/:id?',
    name: 'bookingJoin',
    props: true,
    component: BookingJoin,
  },
  {
    path: '/booking-results/:id',
    name: 'bookingResults',
    props: true,
    component: BookingResults,
  },
  {
    path: '/Error404',
    name: 'error404Page',
    props: true,
    component: ErrorPage,
  },
  {
    path: '/DisconnectError',
    name: 'disconnectErrorPage',
    props: true,
    component: DisconnectErrorPage,
  },
  {
    path: "/:notFound",
    name: 'notFoundErrorPage',
    props: true,
    component: ErrorPage,
  },
  {
    path: '/pay-error/:saleId/:cancellationLimitDate?/:errorMsg?',
    name: 'payError',
    props: true,
    component: payError,
  },
  {
    path: "/legal",
    name: 'legalView',
    props: true,
    component: LegalView,
  },
  {
    path: "/user",
    name: 'UserProfile',
    props: true,
    component: UserProfileView,
  },
  {
    path: "/create-match",
    name: 'createMatch',
    props: true,
    component: MatchCreationView,
  },
  {
    path: "/remove-account",
    name: 'removeAccount',
    props: true,
    component: RemoveAccountView,
  },
  {
    path: "/support",
    name: 'support',
    props: true,
    component: SuportView,
  },
  {
    path: "/chat/:bookingId/:token?/:id?",
    name: 'chatView',
    props: true,
    component: ChatView,
  },
  {
    path: "/link-account-response/:response",
    name: 'linkAccountResponse',
    props: true,
    component: linkAccountResponse,
  },
  {
    path: '/payment-bridge/:payload',
    name: 'PaymentBridge',
    props: true,
    component: PaymentBridge,
  },
  {
    path: '/iframe-redirect/:payload',
    name: 'IframeRedirect',
    props: true,
    component: IframeRedirect,
  },
  {
    path: '/leveling/:userToken/:sportId',
    name: 'LevelingGender',
    props: true,
    component: LevelingGender,
  },
  {
    path: '/payment/azul/:MerchantId?/:MerchantName?/:MerchantType?/:CurrencyCode?/:OrderNumber?/:Amount?/:ITBIS?/:ApprovedUrl?/:DeclinedUrl?/:CancelUrl?/:UseCustomField1?/:UseCustomField2?/:AuthHash?/:ShowTransactionResult?/:Locale?/:SaveToDataVault?',
    name: 'AzulPayment',
    props: true,
    component: Azul,
  },
  {
    path: '/payment-redirect/:paymentProvider/:payload',
    name: 'PaymentRedirect',
    props: true,
    component: PaymentRedirect,
  },
  {
    path: '/:catchAll(.*)', //Match any unmatched route
    name: 'catchAllErrorPage',
    component: ErrorPage, //Component to display the error message
  },
  {
    path: '/transbank-validation',
    name: 'TransbankValidation',
    component: TransbankValidationView,
  },
  {
    path: '/activity/:id?',
    name: 'activityJoin',
    props: true,
    component: ActivityJoin,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (store.state.globalErrorIsOpen === true) {
    store.commit("closeGlobalError");
    next(false); // Cancel navigation 
  } else {
    next(); // Continue with navigation
  }
});

export default router;
