import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5ada786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center cursor-pointer" }
const _hoisted_2 = { class: "icon-selector" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex flex-col items-center" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item",
          onClick: ($event: any) => (_ctx.selectItem(index))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: item.selected ? item.altIcon : item.icon,
              class: "icon"
            }, null, 8, _hoisted_5),
            _createElementVNode("p", {
              class: _normalizeClass(["text", { selected: _ctx.selectedIndex === index }])
            }, _toDisplayString(_ctx.addThreeDotsText(item.text)), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["indicator h-1 w-full", { selectIndicator: _ctx.selectedIndex === index }])
            }, null, 2)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}