<template>
  <span>
    <div class="relative h-full">
      <div id="payment-element"></div>
      <ColorButton
        :text="$t('Pay')"
        v-if="showPayButton"
        @click="stripeCheckout($event)"
        class="md:mt-8 sm:mt-4"
      ></ColorButton>
    </div>
  </span>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import loadScript from "load-script";
import { PaymentApi } from "@/service/PaymentApi";
import ColorButton from "@/components/buttons/colorButton.vue";
import { CreateStripePaymentIntentRequest } from "models/create-stripe-payment-intent-request";

export default defineComponent({
  name: "stripeForm",
  data() {
    return {
      token: "",
      error: "",
      stripe: {} as any,
      elements: {} as any,
      showPayButton: false,
    };
  },
  components: { ColorButton },
  props: ["data", "cardId", "user"],
  emits: ["paymentExecuted"],
  async mounted() {
    const payload:CreateStripePaymentIntentRequest = {
      saleId: this.data.id,
      currencyCode: this.data.amountData.currencyCode, 
      amount: this.data.amountData.grossAmount
    }
    const response = await PaymentApi.createStripePaymentIntent(payload);

    const clientSecret = response.data.clientSecret;
    const targetElement: any = document.getElementById("payment-element");
    targetElement.innerHTML = "";

    await this.loadStripeScript();
    await this.loadStripeForm(clientSecret, this.data.paymentProviderData.properties.publicKey);
  },
  methods: {
    async loadStripeScript() {
      const scriptLoaded = ref(false);
      const scriptUrl = process.env.VUE_APP_STRIPE_INSITE_SCRIPT;

      await new Promise((resolve, reject) => {
        loadScript(scriptUrl, (err, script: any) => {
          if (err) {
            reject(err);
          } else {
            scriptLoaded.value = true;
            resolve(script);
          }
        });
      });
    },
    async loadStripeForm(clientSecret: string, publicKey: string) {
      const w: any = window;
      const Stripe = w.Stripe;
      this.stripe = Stripe(publicKey);
      const appearance = {
        theme: "stripe",
      };
      this.elements = this.stripe.elements({ appearance, clientSecret });
      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = this.elements.create(
        "payment",
        paymentElementOptions
      );
      
      setTimeout(() => {
        paymentElement.mount("#payment-element");      
        paymentElement.on('change', (event: any) => {
          this.showPayButton = event.complete;
        });
      }, 1);
    },
    async stripeCheckout(e: any) {
      this.showPayButton = false;
      e.preventDefault();
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: document.location + "?payment_redirect=stripe&saleId=" + this.data.id, // stripe rellena esa url con el paymentintent
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.error = error.message;
      } else {
        this.error = this.$t("Crash").toString();
      }
    },
  },
});
</script>
<style scoped>
#card-form {
  height: 169px !important;
}
@media (max-width: 500px) {
  #card-form {
    height: 200px !important;
  }
}
</style>