<template>
  <main>
    <HeaderView
      @refreshed="handleRefresh"
    />
    <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
    <div
      id="ResultsContainer"
      class="sm:absolute sm:w-full mt-20"
      v-if="
        tenantInfo !== null &&
        tenantInfo !== undefined &&
        tenantInfo.tenant !== undefined
      "
    >
      <div>
        <div class="ResultImage relative">
          <img class="h-56 w-full sm:hidden" :src="imageUrl" alt="" />
          <img class="h-56 w-full md:hidden" :src="imageUrl" alt="" />
          <div class="overlay"></div>
          <div class="absolute md:p-8 sm:p-5 textTitleResults">
            <div class="md:text-2xl sm:text-xl">
              {{ capitalizeFirstLetter(tenantInfo.tenant.name) }}
            </div>
            <div class="autoCompleteContainer flex md:mt-6 sm:mt-3">
              <img
                class="gps-icon mr-2"
                src="../assets/images/gps.svg"
                alt=""
              />
              <p class="cursor-pointer md:text-sm sm:text-xs">
                {{ tenantInfo.tenant.address }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 flex justify-center ContainerButtonSearch">
        <div class="flex WrapperButtonSearch">
           <div
            class="sm:text-sm cursor-pointer walletButtonDisabled mr-2"
            @click="homeClicked()"
            :class="{ 'walletButton': homeOpen }"
          >
            {{ $t("club_info") }}
          </div>
          <div
            class="sm:text-sm cursor-pointer walletButtonDisabled"
            @click="playClicked()"
            :class="{ 'walletButton': playOpen }"
          >
            {{ $t("play") }}
          </div>
        </div>
      </div>
      <div v-if="playOpen" >
        <ActivityBar
          :optionDefault = activity
          @optionSelected="handleActivitySelected"
        />
      </div>
      <div v-if="bookOpen || matchesOpen || activityOpen" class="mt-4">
        <CalendarButton
          :date="beautifyDateWithOutTime(this.$route.query.date, true, false)"
          :rawDate="this.$route.query.date"
          @getAvailability="handleGetAvailability"
        />
      </div>
      <div>
        <Transition>
          <ClubHome v-if="homeOpen" 
          :tenantInfo="tenantInfo" 
          :showPrivate="showPrivate" 
        />
        </Transition>
        <Transition>
          <ClubBook
            v-if="bookOpen"
            :tenantInfo="tenantInfo"
            @pricesLoaded="pricesLoaded"
            @privateClub="handlePrivateClub"
            ref="clubBookRef"
          />
        </Transition>
        <Transition>
          <ClubMatches 
            v-if="matchesOpen"
            :tenantInfo="tenantInfo"
            @privateClub="handlePrivateClub"
            ref="clubMatchRef"
           />
        </Transition>
        <Transition>
          <ClubActivities 
            v-if="activityOpen"
            :tenantInfo="tenantInfo"
            ref="clubActivityRef"
           />
        </Transition>
      </div>
    </div>
    <Transition>
      <PopUpLight v-if="openModalPrices" @closed="openModalPrices = false">
        <ResourcePrice
          :resources="pricesSelection.resources"
          :date="pricesSelection.date"
          :sport="pricesSelection.sport"
          @priceSelected="priceSelected"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="errorMsg" @closed="closeError()">
        <div class="mt-8">
          {{ errorMsg }} 
        </div>
      </PopUpLight>
    </Transition>
  </main>
</template>
<script>
import ClubHome from "@/components/booking/clubHome.vue";
import ClubBook from "@/components/booking/clubBook.vue";
import ClubMatches from "@/components/booking/clubMatches.vue";
import ClubActivities from "@/components/booking/clubActivities.vue";
import { useStore } from "vuex";
import PopUpLight from "@/components/popup/popupLight.vue";
import ResourcePrice from "@/components/booking/resourcePrice.vue";
import { setTimeToDate, formatDate, beautifyDateWithOutTime } from "@/helpers/dateHelper";
import { Category, DEFAULT_TENANT_IMAGE, BookingViewTabs, SearchType, BookingType } from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import HeaderView from "../components/header/header.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import HistoryBack from "@/components/form/historyBack.vue";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";
import { Format } from "@/helpers/formatHelper";
import { TenantHelper } from "@/helpers/tenantHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import CustomerHelper from "@/helpers/customerHelper";
import CalendarButton from "@/components/form/calendarButton.vue";
import ActivityBar from "@/components/booking/activityBar.vue"


export default {
  name: "bookingView",
  components: {
    ClubHome,
    ClubBook,
    ClubMatches,
    ClubActivities,
    PopUpLight,
    ResourcePrice,
    HeaderView,
    HistoryBack,
    CalendarButton,
    ActivityBar
  },
  data() {
    return {
      openModal: false,
      homeOpen: false,
      bookOpen: true,
      activityOpen: false,
      playOpen: true,
      matchesOpen: false,
      tenantInfo: {},
      errorMsg: null,
      openModalPrices: false,
      pricesSelection: {},
      isUserLogged: false,
      refreshAvailabilty: false,
      imageUrl: DEFAULT_TENANT_IMAGE,
      showPrivate: false,
      activity: BookingType.BOOKING,
      activityParamLoaded: false
    };
  },
  async mounted() {
    if (window.location.href.includes("booking")) {
      document.body.style.overflowY = "scroll";
    }
    const route = this.$route;
    let tabQueryParam = route.query.tab;
    const categoryParam = route.query.category;
    if(!tabQueryParam){
      switch (Number(categoryParam)) {
        case 0:
          tabQueryParam = 1;
          break;
        case 1:
          tabQueryParam = 2;
          break;
        case 2:
          tabQueryParam = 3;
        break;
      }
    }
    this.activity = tabQueryParam;
    if(!Format.IsNull(tabQueryParam)) {
      switch (Number(tabQueryParam)) {
        case BookingViewTabs.HOME:
          this.homeClicked();
          break;
        case BookingViewTabs.BOOKING:
          this.activity = BookingType.BOOKING;
          this.handleActivitySelected(this.activity);
          break;
        case BookingViewTabs.MATCH:
          this.activity = BookingType.MATCH;
          this.handleActivitySelected(this.activity);
          break;
        case BookingViewTabs.ACTIVITY:
          this.activity = BookingType.ACTIVITY;
          this.handleActivitySelected(this.activity);
          break;
      }
    }else if(!Format.IsNull(categoryParam)) {
      if(categoryParam == Category.MATCH){
        this.activity = BookingType.MATCH;
        this.handleActivitySelected(this.activity);
      }
    }
    document.body.style.background = "none"; // todo hacerlo bien con layouts
    this.store = useStore();
    if (Format.IsNull(this.store.state.tenantInfo.tenant)) {
      let availability = null;
      
      if(
          categoryParam == Category.MATCH ||
          categoryParam == Category.ACTIVITY
        ){
        availability = await this.loadTenantInfo();
        this.tenantInfo.tenant = availability;
        this.imageUrl = this.tenantInfo.tenant.gallery.split(" ")[0];
        return;
      }

      availability = await this.loadTenantAvailability();
      if (!Format.IsNull(availability)) {
        this.tenantInfo = availability[0];
        this.imageUrl = this.tenantInfo.tenant.gallery.split(" ")[0];
      }
      return;
    }
    this.tenantInfo = this.store.state.tenantInfo;
    this.refreshAvailabilty = true;
    this.imageUrl = this.tenantInfo.tenant.gallery.split(" ")[0];
    this.fixIphoneHeight();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name.toLocaleLowerCase() === "checkoutview" ||
      !this.openModalPrices
    ) {
      next();
    } else {
      this.openModalPrices = false;
      next(false); // stop navigation
    }
  },
  methods: {
    beautifyDateWithOutTime,
    capitalizeFirstLetter,
    async handleRefresh() {
      this.$refs.clubActivityRef.parentCallGetAvailability(new Date(this.$route.query.date));
    },
    handleActivitySelected(activitySelected) {
      switch(activitySelected) {
        case BookingType.BOOKING:
          this.bookingClicked();
          break;
        case BookingType.MATCH:
          this.matchesClicked();
          break;
        case BookingType.ACTIVITY:
          this.activityClicked();
          break;
      }
    },
    homeClicked() {
      this.homeOpen = true;
      this.playOpen = false;
      this.bookOpen = false;
      this.matchesOpen = false;
    },
    playClicked() {
      this.homeOpen = false;
      this.playOpen = true;
      this.handleActivitySelected(this.activity);
    },
    activityClicked() {
        this.activityOpen = true;
        this.matchesOpen = false;
        this.bookOpen = false;
    },
    bookingClicked() {
        this.bookOpen = true;
        this.matchesOpen = false;
        this.activityOpen = false;
    },
    matchesClicked() {
      this.isUserLogged = CustomerHelper.loginIfNotLogged(true);
      if (!this.isUserLogged) return;
        this.bookOpen = false;
        this.matchesOpen = true;
        this.activityOpen = false;
    },
    async pricesLoaded(data) {
      this.showPrivate = false;
      if (this.refreshAvailabilty == true) {
        const availability = await this.loadTenantAvailability();
        if (availability !== undefined && availability.length > 0) {
          this.tenantInfo = availability[0];
          this.refreshAvailabilty = false;
        }
      }
      this.pricesSelection = data;
      this.openModalPrices = true;
    },
    getDateSystemFormat() {
      const activeHour = this.$route.query.hour;
      const activeDate = setTimeToDate(
        new Date(this.$route.query.date),
        activeHour
      );
      return formatDate(activeDate);
    },
    async loadTenantInfo(){
      const response = await TenantHelper.findTenantByIdFromPandora(this.$route.query.id);
      if (Format.IsNull(response)) {
        customGlobalError(this.$t("search_no_resources_found"), "/");
      }
      return response;
    },
    async loadTenantAvailability() {
      const request = {
        tenantIds: [this.$route.query.id],
        date: this.$route.query.date,
        sport: this.$route.query.sport,
        category: BookingType.BOOKING,
        searchType: SearchType.FULL,
        radius: this.$route.query.radius,
      };
      
      const message = await BookingHelper.messageAvailability(request);
      if (message.bookings?.length == 0) {
        customGlobalError(this.$t("search_no_resources_found"), "/");
      }
      return message.bookings;
    },
    async handleGetAvailability(date){
      if(this.bookOpen){
        this.$refs.clubBookRef.parentCallGetAvailability(date);
        return;
      }
      if(this.matchesOpen){
        this.$refs.clubMatchRef.parentCallGetAvailability(date);
        return;
      }
      if(this.activityOpen){
        this.$refs.clubActivityRef.parentCallGetAvailability(date);
        return;
      }
    },
    handlePrivateClub() {
      this.homeClicked();
      this.showPrivate = true;
    },
    priceSelected(booking) {
      booking.tenant = this.tenantInfo.tenant.name;
      booking.tenantId = this.tenantInfo.tenant.id;
      booking.cordX = this.tenantInfo.tenant.cordX;
      booking.cordY = this.tenantInfo.tenant.cordY;
      booking.imageUrl = this.imageUrl;
      booking.allowedPaymentMethods = this.tenantInfo.tenant.allowedPaymentMethods;
      if (!booking.type) {
        booking.type = {};
      }
      booking.type.code = BookingType.BOOKING;
      const jsonBooking = JSON.stringify(booking);
      const utf8Booking = encodeURIComponent(jsonBooking);
      const encodedBooking = btoa(utf8Booking);
      
      this.$router.push({
        path: "checkout",
        query: { pl: encodedBooking },
      });
    },
    closeError() {
      this.errorMsg = null;
      this.$router.push({ path: "/" });
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const searchContainer = document.getElementById("ResultsContainer");
        if (searchContainer) {
          searchContainer.classList.add("pb-100");
        }
      }
    },
  },
};
</script>
<style>
.walletButtonDisabled {
    background: linear-gradient(var(--background-color-button),
      var(--background-color-button)) padding-box,
    linear-gradient(60deg, grey, grey) border-box;
    border: 2px solid transparent;
    border-radius: 36px;

} 
#ResultsContainer .withLine::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    103.18deg,
    var(--color1) 3.29%,
    var(--color2) 97.77%
  );
}

#ResultsContainer .CloseModal {
  top: 10px;
  right: 10px;
}

#ResultsContainer .selected svg {
  transition: 0.7s;
  transform: rotate(180deg);
}

.ActivitiesRow.active::after {
  background-color: var(--color2);
  content: "";
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  height: 14px;
  width: 14px;
}

.ActivitiesRow.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--color2),
    rgba(243, 243, 243, 0)
  );
  border-radius: 8px;
  opacity: 0.4;
}

.WrapperActivitiesRows .ActivitiesRow {
  border-radius: 8px;
  background-color: var(--background-search);
  height: fit-content;
  max-width: 33%;
}

.WrapperActivitiesRows {
  gap: 5%;
  border-bottom: 1px solid var(--background-search);
  height: 0px;
  overflow: hidden;
  padding: 0 !important;
  transition: 0.5s;
}

#ResultsContainer .ActivitiesModal {
  height: fit-content;
  width: 464px;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
  border-radius: 16px;
}

#ResultsContainer .ActivitiesOverlay {
  background: #00000059;
  z-index: 3;
  backdrop-filter: blur(12px);
}

#ResultsContainer .circle.Available {
  background: var(--background-search);
}

#ResultsContainer .circle {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid var(--background-search);
}

#ResultsContainer .ContainerTimeSelector div.active::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--color3),
    rgba(243, 243, 243, 0)
  );
  border-radius: 8px;
  opacity: 0.4;
}

/* #ResultsContainer .ContainerTimeSelector div.active::after {
  background-color: var(--color2);
  content: "";
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  height: 14px;
  width: 14px;
} */
#ResultsContainer .ContainerTimeSelector {
  background: var(--background-color);
}

#ResultsContainer .ContainerTimeSelector div.active.disabled::before {
  display: none;
}
#ResultsContainer .ContainerTimeSelector div.active.disabled::after {
  display: none;
}
#ResultsContainer .ContainerTimeSelector div.disabled {
  background: transparent !important;
  opacity: 0.6;
}

#ResultsContainer .ContainerTimeSelector div {
  position: relative;
  min-width: 22% !important;
}

#ResultsContainer .ContainerTimeSelector div:hover {
  background: var(--color3) !important;
  opacity: 0.6;
}

#ResultsContainer .iconBookResults {
  border-radius: 50%;
}

#ResultsContainer .iconBookResults.active {
  background-color: var(--color2);
}

#ResultsContainer .user-icon {
  padding: 16px 0;
  width: 279px;
}

#ResultsContainer .OpeningOpacityText {
  opacity: 0.8;
  font-size: 14px;
}

#ResultsContainer .OpeningResults {
  border-bottom: 1px solid #ffffff38;
}

#ResultsContainer .ResultImage {
  margin: 0 auto;
  width: 52%;
}

#ResultsContainer .ResultImage img {
  border-radius: 20px;
}

#ResultsContainer .MapsText {
  height: fit-content;
}

#ResultsContainer .MapResuls {
  border-radius: 16px;
  max-height: 250px;
}

#ResultsContainer #map {
  height: 100%;
  width: 100%;
}

#ResultsContainer #map iframe {
  height: 100%;
  width: 100%;
}

/*#ResultsContainer path {
    fill: #ffffff !important;
    stroke: #000000 !important;
}*/

#ResultsContainer .containerMapsResults {
  gap: 6%;
}

#ResultsContainer .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(#010e2103, var(--background-color));
  z-index: 1;
}

#ResultsContainer .ContainerButtonSearch {
  margin-bottom: 12px;
}

#ResultsContainer .ContainerButtonSearch .WrapperButtonSearch {
  width: fit-content;
}

#ResultsContainer .ContainerButtonSearch .WrapperButtonSearch div {
  padding: 2px 50px;
}

#ResultsContainer .textTitleResults {
  z-index: 1;
  bottom: -51px;
}

#ResultsContainer .CalendarMaps {
  background: var(--background-color);
}

@media (min-width: 767px) {
  #ResultsContainer .selected .WrapperActivitiesRows {
    gap: 5%;
    border-bottom: 1px solid var(--background-search);
    height: 130px;
    overflow: hidden;
    padding: 0.75rem !important;
    transition: 0.5s;
  }
  #ResultsContainer .CalendarMaps {
    width: 47%;
    border-radius: 24px;
    min-height: 406px;
  }

  #ResultsContainer {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  #ResultsContainer .selected .WrapperActivitiesRows {
    gap: 5%;
    border-bottom: 1px solid var(--background-search);
    height: 80px;
    overflow: initial;
    transition: 0.5s;
  }
  #ResultsContainer .ActivitiesModal {
    width: 98%;
  }
  #ResultsContainer .MapResuls #map {
    height: 200px;
  }
  #ResultsContainer .MapResuls {
    border-radius: 0;
  }
  #ResultsContainer .ContainerButtonSearch .WrapperButtonSearch div {
    padding: 2px 20px;
  }
  #ResultsContainer .ResultImage {
    width: 100%;
  }

  #ResultsContainer .ResultImage .textTitleResults {
    bottom: -48px;
  }

  #ResultsContainer {
    margin: 0 auto;
    /* top: -23px; */
    max-width: 100%;
  }
}

#ResultsContainer {
  margin: 0 auto;
  /* top: -23px; */
  max-width: 100%;
}
</style>