<template>
  <div class="mt-8 br16 p-6">
    <div class="flex items-center justify-center"><p>{{ $t("my_matches") }}</p></div>
    <div v-if="result && result.length > 0" class="mt-12 br16 p-4 overflow-y-auto">
      <ul>
        <MatchAvailability
          :matches="result"
          fromRouteName="view"
          @matchSelected="handleMatchSelected"
          @bookingCancelled="handleBookingCancelled"
          />
      </ul>
      <!--
      <ListPagination
        :start = "start"
        :size = "size"
        @getData = "handleGetData"
      />
      -->
    </div>
    <div v-else class="flex items-center justify-center mt-12 br16 altBackground p-4"><p>{{ $t("dont_have_any_match") }}</p></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BookingHelper } from "@/helpers/bookingHelper";
import { Booking } from "models"
import ListPagination from "@/components/form/listPagination.vue";
import MatchAvailability from "@/components/search/matchAvailability.vue";
import store from '@/store';

export default defineComponent({
    name: "MyMatches",
    data() {
      return {
        start: 0,
        size: 50,
        result: [] as Booking[],
      };
    },
    components: {
      MatchAvailability
    },
    async mounted() {
      this.loadData(this.start, this.size);
    }, 
    methods: {
      async handleGetData(start:number, size:number){
        this.loadData(start, size);
      },
      async loadData(start:number, size:number){
        store.commit("setLoading", true);
        this.result = await BookingHelper.getCustomersBooking(start, size);
        store.commit("setLoading", false);
      },
      handleMatchSelected(match: Booking){
        return this.$router.push(`/booking-join?b=${match.id}`);
      },
      async handleBookingCancelled(){
        this.loadData(this.start, this.size);
      }
    }
});
</script>

<style>
.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}
.altBackground {
  box-shadow: 0px 0px 15px -4px #3c7eeb;
}
.containerImageMatch {
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
}
.divisor {
  width: 3px;
}
.end {
  justify-content: end;
}
.usersScore {
  position: absolute;
  right: 10px;
  top: 5px;
}
.scoreUsers {
  top: -5px;
  font-size: 10px;
  right: -10px;
  font-weight: bold;
  color: black;
}
.numberScore {
  top: 3px;
  left: 4px;
}

/* Override for a working scroll*/
@media (min-width: 1100px) {
  body {
    overflow: auto !important;
  }
}
</style>

