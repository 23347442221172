<template>
  <div class="mt-8">
    <form ref="paymentForm" @submit.prevent="submitForm" :action="data.paymentProviderData.paymentPageUrl" method="post" id="paymentForm" name="paymentForm">
      <input type="hidden" name = "MerchantId" :value="data.paymentProviderData.paymentPageParameters.MerchantId"/>
      <input type="hidden" name = "MerchantName" :value="data.paymentProviderData.paymentPageParameters.MerchantName"/>
      <input type="hidden" name = "MerchantType" :value="data.paymentProviderData.paymentPageParameters.MerchantType"/>
      <input type="hidden" name = "CurrencyCode" :value="data.paymentProviderData.paymentPageParameters.CurrencyCode"/>
      <input type="hidden" name = "OrderNumber" :value="data.paymentProviderData.paymentPageParameters.OrderNumber"/>
      <input type="hidden" name = "Amount" :value="data.paymentProviderData.paymentPageParameters.Amount"/>
      <input type="hidden" name = "ITBIS" :value="data.paymentProviderData.paymentPageParameters.ITBIS"/>
      <input type="hidden" name = "ApprovedUrl" :value="data.paymentProviderData.paymentPageParameters.ApprovedUrl"/>
      <input type="hidden" name = "DeclinedUrl" :value="data.paymentProviderData.paymentPageParameters.DeclinedUrl"/>
      <input type="hidden" name = "CancelUrl" :value="data.paymentProviderData.paymentPageParameters.CancelUrl"/>
      <input type="hidden" name = "UseCustomField1" :value="data.paymentProviderData.paymentPageParameters.UseCustomField1"/>
      <input type="hidden" name = "UseCustomField2" :value="data.paymentProviderData.paymentPageParameters.UseCustomField2"/>
      <input type="hidden" name = "AuthHash" :value="data.paymentProviderData.paymentPageParameters.AuthHash"/>
      <input type="hidden" name = "ShowTransactionResult" :value="data.paymentProviderData.paymentPageParameters.ShowTransactionResult"/>
      <input type="hidden" name = "SaveToDataVault" :value="data.paymentProviderData.paymentPageParameters.SaveToDataVault"/>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "azulForm",
  props: ["data", "cardId", "user"],
  mounted() {
    this.submitForm();
  },
  methods: {
    submitForm() {
      const form = document.getElementById('paymentForm') as HTMLFormElement;
      form.submit();
    }
  },
});
</script>
<style scoped>
.iframe {
  width: 100%;
  height: 700px;
  overflow: hidden;
  border-radius: 2px;
  display: block;
}
</style>